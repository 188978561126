import React from 'react';
import {useNavigate} from "react-router-dom";
import PeiLianHeader from "../../headers/PeiLianHeader";

const ChatStreamHome = () => {
    const navigate = useNavigate();

    function changePage() {
        navigate('/peilian/chatStream/configuration');
    }
    return (
        <div className="min-h-screen flex flex-col">
            <PeiLianHeader />
        <div className="min-h-screen bg-gradient-to-br from-gray-100 via-purple-100 to-pink-100 flex flex-col justify-center items-center p-4">

            <div className="text-center max-w-2xl">
                <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">

                    实时语音 <br /><br />Demo
                </h1>
                <button className="bg-gray-900 text-white font-semibold py-3 px-6 rounded-full hover:bg-gray-800 transition duration-300 mb-8" onClick={changePage}>
                    Try demo
                </button>
                <div className="space-y-4">
                    <a href="#" className="block text-indigo-600 hover:underline">
                        ℹ️ Learn more about the RTVI standard
                    </a>
                    <a href="#" className="block text-indigo-600 hover:underline">
                        💻 Demo source code
                    </a>
                </div>
            </div>
        </div>
        </div>
    );
};

export default ChatStreamHome;