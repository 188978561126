import React from 'react';
import { Button } from "antd";
// import { Button } from '@/components/ui/button';

const RealTimeVoicePage = () => {
    return (
        <div className="flex flex-col md:flex-row items-center justify-center min-h-[500px] h-[500px] bg-gradient-to-r from-green-100 to-blue-100 p-8">
            <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0 text-left">
                <h1 className="text-4xl font-bold mb-4 text-gray-800">实时语音对话</h1>
                <p className="text-lg mb-6 text-gray-600">
                    我们的实时语音对话产品提供流畅、高效的沟通体验，适用于各种场景。无论是商务会议、在线教育还是社交娱乐，我们的产品都能满足您的需求。
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-600">
                    <li>高质量语音传输</li>
                    <li>实时响应</li>
                    <li>多语言支持</li>
                    <li>跨平台兼容</li>
                </ul>
                <Button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    立即体验
                </Button>
            </div>
            <div className="md:w-1/2 text-center md:text-right h-full">
                <img
                    src="/home/works/voice.png"
                    alt="实时语音对话"
                    className="rounded-lg shadow-lg mx-auto md:ml-auto md:mr-0 h-full object-contain"
                />
            </div>
        </div>
    );
};

export default RealTimeVoicePage;
