import React, {useEffect} from 'react';
import './HomePage.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {useNavigate} from "react-router-dom";
import HomeHeader from "../headers/HomeHeader"; // requires a loader

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // 如果你没有导入CSS文件，请确保导入


// ServiceCard 组件

const ServiceCard = ({ icon, title, description, link }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        // if (link.startsWith('http')) {
        //     window.open(link, '_blank');
        // } else {
        //     navigate(link);
        // }
    };

    return (
        <div className="service-card" onClick={handleClick}>
            <div className="icon">
                <img src={icon} />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
};



// 主应用组件
const HomePage = () => {
    const navigate = useNavigate();
    return (
        <div className="App">
            <HomeHeader />
            <div className="text-center py-8 bg-gray-300"> {/* Changed to bg-gray-100 for light gray */}
                <h1 className="text-4xl font-bold mb-2">SOTA WORK</h1>
                <h2 className="text-3xl font-bold mb-4">索塔沃科技</h2>
                <p className="text-lg mb-1">State of the Art Work 最佳艺术作品</p>
                <p className="text-lg mb-1">State of the AI Work 最佳AI作品</p>
                <p className="text-lg mb-1">State of the Maker Work 最佳创客作品</p>
            </div>
            <Carousel className="carousel" showThumbs={false} autoPlay={true}>
                <div >
                    <img src='/home/photos/img_1.png' alt="slide1" />
                </div>
                <div >
                    <img src='/home/photos/img_voice.png' alt="slide2"  />
                </div>
            </Carousel>
            <div className="container mx-auto px-4">
                <h2 className="text-2xl font-bold mb-6 text-left">产品列表</h2>

            <div className="service-cards">
                <ServiceCard
                    icon='/home/xiaoge.png'
                    title="小格机器人"
                    link="http://agents.sotawork.com/chatbot/3lG4lt2sxLoxX9OZ"
                    description="格莱美私域问答机器人"
                />
                <ServiceCard
                    icon="/home/robot.svg"
                    title="陪练助手"
                    link="/peilian"
                    description="索塔沃智能陪练，专为企业各服务场景打造，融合大模型及多项AI技术， 助力员工提升专业服务水平提高培训师课程开发效率，为企业创造卓越价值。"
                />
                <ServiceCard
                    icon="/home/marketing.svg"
                    title="AI营销"
                    description="自媒体运营推广，自媒体运营推广自媒体运营推广自媒体运营推广服务。"
                />
                <ServiceCard
                    icon="/home/peixun.svg"
                    title="培训平台"
                    description="格莱美培训平台,包含考题、考核。"
                    link="/peixun/question"
                />
                <ServiceCard
                    icon="/home/platform.svg"
                    title="创作平台"
                    description="格莱美创作中心,包含对话、创作、角色模拟。"
                    link="https://fox.sotawork.com"
                />
            </div>
            <footer className="bg-purple-900 bg-opacity-50 py-4">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-300">&copy; 2024 索塔沃科技有限公司. All rights reserved.</p>
                    <p className="mt-2 text-cyan-200">一起探索AI的无限可能</p>
                </div>
            </footer>
        </div>
        </div>
    );
};

export default HomePage;
