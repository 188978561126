import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Mic } from 'lucide-react';

const MicrophoneSelect = () => {
    const [microphones, setMicrophones] = useState([]);
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const [displayVolume, setDisplayVolume] = useState(0);
    const [isCalibrating, setIsCalibrating] = useState(false);
    const volumeRef = useRef(0);
    const volumeBarRef = useRef(null);
    const noiseThresholdRef = useRef(0);

    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const animationFrameRef = useRef(null);

    useEffect(() => {
        const getMicrophones = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const mics = devices.filter(device => device.kind === 'audioinput');
            setMicrophones(mics);
            if (mics.length > 0) {
                setSelectedMicrophone(mics[0].deviceId);
            }
        };

        getMicrophones();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (selectedMicrophone) {
            startAudioAnalysis();
        }
    }, [selectedMicrophone]);

    const calibrateMicrophone = useCallback(() => {
        setIsCalibrating(true);
        let calibrationSamples = [];
        let sampleCount = 0;
        const totalSamples = 100; // Collect 100 samples for calibration

        const collectSample = () => {
            analyserRef.current.getByteFrequencyData(dataArrayRef.current);
            const maxVolume = Math.max(...dataArrayRef.current) / 255;
            calibrationSamples.push(maxVolume);
            sampleCount++;

            if (sampleCount < totalSamples) {
                setTimeout(collectSample, 50); // Collect a sample every 50ms
            } else {
                // Calculate the noise threshold
                const averageNoise = calibrationSamples.reduce((a, b) => a + b, 0) / totalSamples;
                noiseThresholdRef.current = averageNoise + 0.05; // Set threshold slightly above average noise
                setIsCalibrating(false);
                console.log('Calibration complete. Noise threshold:', noiseThresholdRef.current);
            }
        };

        collectSample();
    }, []);

    const startAudioAnalysis = useCallback(async () => {
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedMicrophone ? { exact: selectedMicrophone } : undefined }
            });

            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            source.connect(analyserRef.current);

            analyserRef.current.fftSize = 32;
            const bufferLength = analyserRef.current.frequencyBinCount;
            dataArrayRef.current = new Uint8Array(bufferLength);

            calibrateMicrophone();

            const updateVolume = () => {
                analyserRef.current.getByteFrequencyData(dataArrayRef.current);
                const maxVolume = Math.max(...dataArrayRef.current) / 255;

                // Apply noise threshold
                const adjustedVolume = Math.max(0, maxVolume - noiseThresholdRef.current) / (1 - noiseThresholdRef.current);

                volumeRef.current = adjustedVolume;
                if (volumeBarRef.current) {
                    volumeBarRef.current.style.width = `${adjustedVolume * 100}%`;
                }
                setDisplayVolume(adjustedVolume);

                animationFrameRef.current = requestAnimationFrame(updateVolume);
            };

            updateVolume();
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    }, [selectedMicrophone, calibrateMicrophone]);

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                麦克风:
            </label>
            <div className="relative">
                <select
                    value={selectedMicrophone}
                    onChange={(e) => setSelectedMicrophone(e.target.value)}
                    className="block appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {microphones.map((mic) => (
                        <option key={mic.deviceId} value={mic.deviceId}>
                            {mic.label || `Microphone ${mic.deviceId.slice(0, 5)}`}
                        </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                    <Mic size={24} />
                </div>
            </div>
            <div className="mt-2 bg-gray-200 rounded-full h-2">
                <div
                    ref={volumeBarRef}
                    className="bg-green-500 rounded-full h-2 transition-all duration-50 ease-in-out"
                    style={{ width: '0%' }}
                ></div>
            </div>
            <div className="mt-1 text-sm text-gray-600">
                {isCalibrating ? '校准中...' : `当前音量: ${(displayVolume * 100).toFixed(2)}%`}
            </div>
            <button onClick={calibrateMicrophone} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded">
                重新校准
            </button>
        </div>
    );
};

export default MicrophoneSelect;