// import React, { useState, useEffect } from 'react';
// import { Mic } from 'lucide-react';
// import './ChatStream.css';
// import PeiLianHeader from "../../headers/PeiLianHeader";
// import { useLocation } from "react-router-dom";
// import {RateLimitError, VoiceClient, VoiceClientOptions} from "realtime-ai";
//
// const BOT_READY_TIMEOUT = 5000;  // 定义超时时间为5秒
//
// const VoiceChatStream = () => {
//     const [isRecording, setIsRecording] = useState(false);
//     const [userStatus, setUserStatus] = useState('Connected');
//     const [error, setError] = useState('');
//     const location = useLocation();
//     const { model } = location.state || { model: 'Unknown Model' };
//
//     // const voiceClientOptions: VoiceClientOptions = {
//     //     baseUrl: 'http://localhost:8080',  // 设置为你的后端URL
//     //     enableMic: true,
//     //     enableCam: false,
//     //     callbacks: {
//     //         onResult: (text) => {
//     //             console.log('Received text:', text);
//     //             // 在这里处理从后端接收到的文本
//     //         },
//     //         onError: (error) => {
//     //             console.error('Error:', error);
//     //             setError('An error occurred during voice processing.');
//     //         },
//     //         onVoiceData: (voiceData) => {
//     //             fetch(`${voiceClientOptions.baseUrl}/process-voice`, {
//     //                 method: 'POST',
//     //                 headers: {
//     //                     'Content-Type': 'application/json',
//     //                 },
//     //                 body: JSON.stringify({ voice_data: voiceData }),
//     //             })
//     //                 .then(response => response.json())
//     //                 .then(data => {
//     //                     console.log('Processed text:', data.text);
//     //                     // 处理从后端返回的处理后的文本
//     //                 })
//     //                 .catch(error => {
//     //                     console.error('Error processing voice data:', error);
//     //                     setError('Error processing voice data.');
//     //                 });
//     //         }
//     //     }
//     // };
//     //
//     // const voiceClient = new VoiceClient(voiceClientOptions);
//
//     const startRecording = async () => {
//         setIsRecording(true);
//         setUserStatus('Speaking');
//         try {
//             await start();
//         } catch (error) {
//             console.error('Error starting recording:', error);
//             setError('Error starting recording.');
//         }
//     };
//
//     const stopRecording = () => {
//         setIsRecording(false);
//         setUserStatus('Connected');
//         // voiceClient.stopListening();
//     };
//
//     const start = async () => {
//         if (!voiceClient) return;
//
//         // Set a timeout and check for join state, incase under heavy load
//         setTimeout(() => {
//             if (voiceClient.state !== "ready") {
//                 setError(
//                     "Bot failed to join or enter ready state. Server may be busy. Please try again later."
//                 );
//                 voiceClient.disconnect();
//             }
//         }, BOT_READY_TIMEOUT);
//
//         // Join the session
//         try {
//             // Disable the mic until the bot has joined
//             voiceClient.enableMic(false);
//             await voiceClient.start();
//         } catch (e) {
//             if (e instanceof RateLimitError) {
//                 setError("Demo is currently at capacity. Please try again later.");
//             } else {
//                 setError(
//                     "Unable to authenticate. Server may be offline or busy. Please try again later."
//                 );
//             }
//             return;
//         }
//     };
//
//     return (
//         <div className="min-h-screen flex flex-col">
//             <PeiLianHeader />
//             <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
//                 <div className="w-96 bg-white rounded-lg shadow-lg overflow-hidden">
//                     <div className="p-4 bg-gray-200 text-center text-sm font-semibold">
//                         {model}
//                     </div>
//                     <div className="p-8 flex justify-center">
//                         <div className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center">
//                             <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center">
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="px-4 py-2 flex justify-between text-sm">
//                         <div>
//                             <div className="text-gray-500">USER STATUS</div>
//                             <div className={userStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
//                                 {userStatus}
//                             </div>
//                         </div>
//                         <div className="text-center">
//                             <div className="text-gray-500">LATENCY</div>
//                             <div>300<span className="text-gray-500 text-xs">ms</span></div>
//                             <div className="text-gray-500 text-xs">avg 300 ms</div>
//                         </div>
//                         <div className="text-right">
//                             <div className="text-gray-500">BOT STATUS</div>
//                             <div className="text-blue-500">Connected</div>
//                         </div>
//                     </div>
//                     {error && (
//                         <div className="px-4 py-2 bg-red-200 text-red-800 text-sm rounded">
//                             {error}
//                         </div>
//                     )}
//                 </div>
//                 <div className="mt-8">
//                     <button
//                         className={`w-16 h-16 rounded-full flex items-center justify-center ${
//                             isRecording ? 'bg-red-500' : 'bg-blue-500'
//                         } text-white shadow-lg transition-colors duration-300`}
//                         onMouseDown={startRecording}
//                         onMouseUp={stopRecording}
//                         onMouseLeave={stopRecording}
//                         onTouchStart={startRecording}
//                         onTouchEnd={stopRecording}
//                     >
//                         <Mic size={32} />
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default VoiceChatStream;


import React, { useState } from 'react';
import { Mic } from 'lucide-react';
import './ChatStream.css';
import PeiLianHeader from "../../headers/PeiLianHeader";
import {useLocation} from "react-router-dom";
const VoiceChatStream = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [userStatus, setUserStatus] = useState('Connected');
    const location = useLocation();
    const { model } = location.state || { model: 'Unknown Model' };
    const startRecording = () => {
        setIsRecording(true);
        setUserStatus('Speaking');
        // 这里添加开始录音的逻辑
    };

    const stopRecording = () => {
        setIsRecording(false);
        setUserStatus('Connected');
        // 这里添加停止录音的逻辑
    };

    return (
        <div className="min-h-screen flex flex-col">
            <PeiLianHeader />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                <div className="w-96 bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-4 bg-gray-200 text-center text-sm font-semibold">
                        {model}
                    </div>
                    <div className="p-8 flex justify-center">
                        <div className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center">
                            <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center">
                                <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
                                <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-2 flex justify-between text-sm">
                        <div>
                            <div className="text-gray-500">USER STATUS</div>
                            <div className={userStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
                                {userStatus}
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="text-gray-500">LATENCY</div>
                            <div>300<span className="text-gray-500 text-xs">ms</span></div>
                            <div className="text-gray-500 text-xs">avg 300 ms</div>
                        </div>
                        <div className="text-right">
                            <div className="text-gray-500">BOT STATUS</div>
                            <div className="text-blue-500">Connected</div>
                        </div>
                    </div>
                </div>
                <div className="mt-8">
                    <button
                        className={`w-16 h-16 rounded-full flex items-center justify-center ${
                            isRecording ? 'bg-red-500' : 'bg-blue-500'
                        } text-white shadow-lg transition-colors duration-300`}
                        onMouseDown={startRecording}
                        onMouseUp={stopRecording}
                        onMouseLeave={stopRecording}
                        onTouchStart={startRecording}
                        onTouchEnd={stopRecording}
                    >
                        <Mic size={32} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VoiceChatStream;

//
//
// import React, { useState, useRef, useEffect } from 'react';
// import { Mic } from 'lucide-react';
// import './ChatStream.css';
// import PeiLianHeader from "../../headers/PeiLianHeader";
// import { useLocation } from "react-router-dom";
// import {RateLimitError, VoiceClient, VoiceClientOptions} from "realtime-ai";
//
// const BOT_READY_TIMEOUT = 5000;  // 定义超时时间为5秒
//
// const VoiceChatStream = () => {
//     const [isRecording, setIsRecording] = useState(false);
//     const [userStatus, setUserStatus] = useState('Connected');
//     const [error, setError] = useState('');
//     const [volume, setVolume] = useState(0);
//     const location = useLocation();
//     const { model } = location.state || { model: 'Unknown Model' };
//     const voiceClientRef = useRef(null);
//     const audioContextRef = useRef(null);
//     const analyserRef = useRef(null);
//     const microphoneRef = useRef(null);
//     const dataArrayRef = useRef(null);
//     const animationFrameIdRef = useRef(null);
//
//     useEffect(() => {
//         const voiceClientOptions: VoiceClientOptions = {
//             baseUrl: 'http://localhost:8080',  // 设置为你的后端URL
//             enableMic: true,
//             enableCam: false,
//             callbacks: {
//                 onResult: (text) => {
//                     console.log('Received text:', text);
//                     // 在这里处理从后端接收到的文本
//                 },
//                 onError: (error) => {
//                     console.error('Error:', error);
//                     setError('An error occurred during voice processing.');
//                 },
//                 onVoiceData: (voiceData) => {
//                     fetch(`${voiceClientOptions.baseUrl}/process-voice`, {
//                         method: 'POST',
//                         headers: {
//                             'Content-Type': 'application/json',
//                         },
//                         body: JSON.stringify({ voice_data: voiceData }),
//                     })
//                         .then(response => response.json())
//                         .then(data => {
//                             console.log('Processed text:', data.text);
//                             // 处理从后端返回的处理后的文本
//                         })
//                         .catch(error => {
//                             console.error('Error processing voice data:', error);
//                             setError('Error processing voice data.');
//                         });
//                 }
//             }
//         };
//
//         voiceClientRef.current = new VoiceClient(voiceClientOptions);
//
//         return () => {
//             // 清理 VoiceClient 实例
//             if (voiceClientRef.current) {
//                 voiceClientRef.current.disconnect();
//             }
//             // 停止音频处理
//             if (audioContextRef.current) {
//                 audioContextRef.current.close();
//             }
//             if (animationFrameIdRef.current) {
//                 cancelAnimationFrame(animationFrameIdRef.current);
//             }
//         };
//     }, [model]);
//
//     const startRecording = async () => {
//         setIsRecording(true);
//         setUserStatus('Speaking');
//         try {
//             await start();
//             // 开始音频处理
//             startVolumeDetection();
//         } catch (error) {
//             console.error('Error starting recording:', error);
//             setError('Error starting recording.');
//         }
//     };
//
//     const stopRecording = () => {
//         setIsRecording(false);
//         setUserStatus('Connected');
//         voiceClientRef.current.stopListening();
//         // 停止音频处理
//         if (audioContextRef.current) {
//             audioContextRef.current.close();
//         }
//         if (animationFrameIdRef.current) {
//             cancelAnimationFrame(animationFrameIdRef.current);
//         }
//     };
//
//     const start = async () => {
//         if (!voiceClientRef.current) return;
//
//         // Set a timeout and check for join state, incase under heavy load
//         setTimeout(() => {
//             if (voiceClientRef.current.state !== "ready") {
//                 setError(
//                     "Bot failed to join or enter ready state. Server may be busy. Please try again later."
//                 );
//                 voiceClientRef.current.disconnect();
//             }
//         }, BOT_READY_TIMEOUT);
//
//         // Join the session
//         try {
//             // Disable the mic until the bot has joined
//             voiceClientRef.current.enableMic(false);
//             await voiceClientRef.current.start();
//         } catch (e) {
//             if (e instanceof RateLimitError) {
//                 setError("Demo is currently at capacity. Please try again later.");
//             } else {
//                 setError(
//                     "Unable to authenticate. Server may be offline or busy. Please try again later."
//                 );
//             }
//             return;
//         }
//     };
//
//     const startVolumeDetection = async () => {
//         try {
//             audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//             analyserRef.current = audioContextRef.current.createAnalyser();
//             microphoneRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
//             const source = audioContextRef.current.createMediaStreamSource(microphoneRef.current);
//             source.connect(analyserRef.current);
//             analyserRef.current.fftSize = 256;
//             const bufferLength = analyserRef.current.frequencyBinCount;
//             dataArrayRef.current = new Uint8Array(bufferLength);
//
//             const detectVolume = () => {
//                 analyserRef.current.getByteFrequencyData(dataArrayRef.current);
//                 const volume = dataArrayRef.current.reduce((a, b) => a + b, 0) / bufferLength;
//                 setVolume(volume);
//                 setUserStatus(volume > 50 ? 'Speaking' : 'Connected');  // 设置音量阈值
//                 animationFrameIdRef.current = requestAnimationFrame(detectVolume);
//             };
//
//             detectVolume();
//         } catch (error) {
//             console.error('Error detecting volume:', error);
//             setError('Error detecting volume.');
//         }
//     };
//
//     return (
//         <div className="min-h-screen flex flex-col">
//             <PeiLianHeader />
//             <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
//                 <div className="w-96 bg-white rounded-lg shadow-lg overflow-hidden">
//                     <div className="p-4 bg-gray-200 text-center text-sm font-semibold">
//                         {model}
//                     </div>
//                     <div className="p-8 flex justify-center">
//                         <div className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center">
//                             <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center">
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="px-4 py-2 flex justify-between text-sm">
//                         <div>
//                             <div className="text-gray-500">USER STATUS</div>
//                             <div className={userStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
//                                 {userStatus}
//                             </div>
//                         </div>
//                         <div className="text-center">
//                             <div className="text-gray-500">LATENCY</div>
//                             <div>300<span className="text-gray-500 text-xs">ms</span></div>
//                             <div className="text-gray-500 text-xs">avg 300 ms</div>
//                         </div>
//                         <div className="text-right">
//                             <div className="text-gray-500">BOT STATUS</div>
//                             <div className="text-blue-500">Connected</div>
//                         </div>
//                     </div>
//                     {error && (
//                         <div className="px-4 py-2 bg-red-200 text-red-800 text-sm rounded">
//                             {error}
//                         </div>
//                     )}
//                 </div>
//                 <div className="mt-8">
//                     <button
//                         className={`w-16 h-16 rounded-full flex items-center justify-center ${
//                             isRecording ? 'bg-red-500' : 'bg-blue-500'
//                         } text-white shadow-lg transition-colors duration-300`}
//                         onMouseDown={startRecording}
//                         onMouseUp={stopRecording}
//                         onMouseLeave={stopRecording}
//                         onTouchStart={startRecording}
//                         onTouchEnd={stopRecording}
//                     >
//                         <Mic size={32} />
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default VoiceChatStream;
