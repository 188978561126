
import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import PeiXunHeader from "../headers/PeiXunHeader";

function PeiXunHome() {
    return (
        <div className="peixun-home-container">
            <PeiXunHeader></PeiXunHeader>

            <div className="peixun-home-links">
                <Link to="/peixun/questions" className="peixun-home-link">
                    <div className="peixun-home-link-box">
                        <h2>Question List</h2>
                        <p>View all questions</p>
                    </div>
                </Link>
                <Link to="/peixun/create" className="peixun-home-link">
                    <div className="peixun-home-link-box">
                        <h2>Create Question</h2>
                        <p>Add a new question</p>
                    </div>
                </Link>
                <Link to="/peixun/random" className="peixun-home-link">
                    <div className="peixun-home-link-box">
                        <h2>Random Quiz</h2>
                        <p>Take a random quiz</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default PeiXunHome;
