// // AboutUs.js
// import React from 'react';
//
// const AboutUs = () => {
//     return (
//         <div>
//             <h1>关于我们</h1>
//             {/* 在这里添加关于公司的信息 */}
//         </div>
//     );
// };
//
// export default AboutUs;
// import React from 'react';
// import { motion } from 'framer-motion';
// import HomeHeader from "../headers/HomeHeader";
//
// const AboutUs = () => {
//     return (
//         <div className="bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen text-white">
//             <HomeHeader/>
//             <header className="bg-blue-600 py-16">
//                 <div className="container mx-auto px-4">
//                     <motion.h1
//                         initial={{ opacity: 0, y: -20 }}
//                         animate={{ opacity: 1, y: 0 }}
//                         transition={{ duration: 0.5 }}
//                         className="text-5xl font-bold mb-4"
//                     >
//                         未来智能，现在开始
//                     </motion.h1>
//                     <motion.p
//                         initial={{ opacity: 0, y: 20 }}
//                         animate={{ opacity: 1, y: 0 }}
//                         transition={{ duration: 0.5, delay: 0.2 }}
//                         className="text-xl"
//                     >
//                         用AI重塑世界
//                     </motion.p>
//                 </div>
//             </header>
//
//             <main className="container mx-auto px-4 py-16">
//                 <motion.section
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5 }}
//                     className="mb-16"
//                 >
//                     <h2 className="text-3xl font-bold mb-6">我们的愿景</h2>
//                     <p className="text-lg leading-relaxed">
//                         作为一家充满活力的AI创业公司，我们致力于开发先进的人工智能解决方案，
//                         以应对当今世界最紧迫的挑战。我们相信，通过结合尖端的机器学习算法、
//                         大数据分析和深度学习技术，我们可以为各行各业带来革命性的变革，
//                         提高效率，创造新的可能性，并最终改善人们的生活质量。
//                     </p>
//                 </motion.section>
//
//                 <motion.section
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5, delay: 0.2 }}
//                     className="mb-16"
//                 >
//                     <h2 className="text-3xl font-bold mb-6">我们的技术</h2>
//                     <ul className="list-disc list-inside text-lg leading-relaxed space-y-2">
//                         <li>自然语言处理 (NLP) 和对话式AI</li>
//                         <li>计算机视觉和图像识别</li>
//                         <li>预测分析和决策支持系统</li>
//                         <li>自动化和智能流程优化</li>
//                         <li>边缘计算和IoT集成</li>
//                     </ul>
//                 </motion.section>
//
//                 <motion.section
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5, delay: 0.4 }}
//                     className="mb-16"
//                 >
//                     <h2 className="text-3xl font-bold mb-6">我们的核心产品</h2>
//                     <div className="bg-gray-700 rounded-lg p-6 shadow-lg">
//                         <h3 className="text-2xl font-semibold mb-4">AIAssist Pro</h3>
//                         <p className="text-lg leading-relaxed">
//                             我们的旗舰产品AIAssist Pro是一个多功能AI助手平台，集成了最先进的
//                             自然语言处理和机器学习技术。它能够理解复杂的查询，提供智能建议，
//                             并自动化各种业务流程，大大提高了企业的运营效率和决策质量。
//                         </p>
//                     </div>
//                 </motion.section>
//
//                 <motion.section
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5, delay: 0.6 }}
//                 >
//                     <h2 className="text-3xl font-bold mb-6">我们的团队</h2>
//                     <p className="text-lg leading-relaxed mb-6">
//                         我们的团队由来自世界顶尖大学和科技公司的AI研究人员、工程师和企业家组成。
//                         我们热衷于突破技术边界，并将创新理念转化为实际解决方案。
//                     </p>
//                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//                         {/* 团队成员卡片示例 */}
//                         <div className="bg-gray-700 rounded-lg p-6 shadow-lg">
//                             <img src="/path/to/team-member-image.jpg" alt="团队成员" className="w-32 h-32 rounded-full mx-auto mb-4" />
//                             <h3 className="text-xl font-semibold text-center">张博士</h3>
//                             <p className="text-gray-300 text-center">首席AI科学家</p>
//                         </div>
//                         {/* 可以添加更多团队成员卡片 */}
//                     </div>
//                 </motion.section>
//             </main>
//
//             <footer className="bg-gray-900 py-8">
//                 <div className="container mx-auto px-4 text-center">
//                     <p>&copy; 2024 AI创新科技. All rights reserved.</p>
//                     <p className="mt-2">一起探索AI的无限可能</p>
//                 </div>
//             </footer>
//         </div>
//     );
// };
//
// export default AboutUs;
import React from 'react';
import { motion } from 'framer-motion';
import HomeHeader from "../headers/HomeHeader";

const AboutUs = () => {
    return (
        <div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800 min-h-screen text-white">
            <HomeHeader/>
            <header className="bg-gradient-to-r from-cyan-500 to-blue-500 py-16">
                <div className="container mx-auto px-4">
                    <motion.h1
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-5xl font-bold mb-4"
                    >
                        未来智能，现在开始
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="text-xl"
                    >
                        用AI重塑世界
                    </motion.p>
                </div>
            </header>

            <main className="container mx-auto px-4 py-16">
                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="mb-16"
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">我们的愿景</h2>
                    <p className="text-lg leading-relaxed text-gray-200">
                        作为一家充满活力的AI创业公司，我们致力于开发先进的人工智能解决方案，
                        以应对当今世界最紧迫的挑战。我们相信，通过结合尖端的机器学习算法、
                        大数据分析和深度学习技术，我们可以为各行各业带来革命性的变革，
                        提高效率，创造新的可能性，并最终改善人们的生活质量。
                    </p>
                </motion.section>

                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="mb-16"
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">我们的技术</h2>
                    <ul className="list-disc list-inside text-lg leading-relaxed space-y-2 text-gray-200">
                        <li>自然语言处理 (NLP) 和对话式AI</li>
                        <li>计算机视觉和图像识别</li>
                        <li>预测分析和决策支持系统</li>
                        <li>自动化和智能流程优化</li>

                    </ul>
                </motion.section>

                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="mb-16"
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">我们的核心产品</h2>
                    <div className="bg-purple-800 bg-opacity-50 rounded-lg p-6 shadow-lg backdrop-filter backdrop-blur-lg">
                        <h3 className="text-2xl font-semibold mb-4 text-cyan-200">AIAssist Pro</h3>
                        <p className="text-lg leading-relaxed text-gray-200">
                            我们的旗舰产品小格助手是一个Agent助手，集成了最先进的
                            自然语言处理和机器学习技术。它能够理解复杂的查询，提供智能建议，
                            并自动化各种业务流程，大大提高了企业的运营效率和决策质量。
                        </p>
                    </div>
                </motion.section>

                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">我们的团队</h2>
                    <p className="text-lg leading-relaxed mb-6 text-gray-200">
                        我们的团队由来自世界顶尖大学和科技公司的AI研究人员、工程师和企业家组成。
                        我们热衷于突破技术边界，并将创新理念转化为实际解决方案。
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* 团队成员卡片示例 */}
                        <div className="bg-purple-800 bg-opacity-50 rounded-lg p-6 shadow-lg backdrop-filter backdrop-blur-lg">
                            <img src="/home/aboutUs/cheng.png" alt="团队成员" className="w-32 h-32 rounded-full mx-auto mb-4" />
                            <h3 className="text-xl font-semibold text-center text-cyan-200">程博士</h3>
                            <p className="text-gray-300 text-center">首席AI科学家</p>
                        </div>
                        {/* 可以添加更多团队成员卡片 */}
                    </div>
                </motion.section>
            </main>

            <footer className="bg-purple-900 bg-opacity-50 py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-300">&copy; 2024 索塔沃科技有限公司. All rights reserved.</p>
                    <p className="mt-2 text-cyan-200">一起探索AI的无限可能</p>
                </div>
            </footer>
        </div>
    );
};

export default AboutUs;