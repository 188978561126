import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import PeiLianHeader from "../headers/PeiLianHeader";
import { UserContext } from '../contexts/UserContext';
// import 'antd/dist/antd.css'; // 确保引入 Ant Design 的样式

const FlexibleSignInPage = () => {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [autoRegister, setAutoRegister] = useState(false);
    const [error, setError] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            navigate('/peilian');
        }
    }, [user, navigate]);

    const validateIdentifier = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^1[3-9]\d{9}$/;

        if (emailRegex.test(value) || phoneRegex.test(value)) {
            setError('');
            return true;
        } else {
            setError('请输入有效的邮箱或手机号');
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateIdentifier(identifier)) {
            if (!autoRegister) {
                setIsModalVisible(true);
                return;
            }
            signIn();
        }
    };

    const signIn = () => {
        console.log('Signing in with:', identifier, password, 'Auto-register:', autoRegister);
        // 这里添加实际的登录/注册逻辑
        if (identifier === "admin@sotawork.com" && password === "password") {
            console.log("登录成功");
            setUser({ identifier });
            navigate('/peilian/');
        }
    };

    const handleOk = () => {
        setIsModalVisible(false)
        setAutoRegister(true)
        // signIn();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex flex-col">
            <PeiLianHeader />
            <div className="flex-grow flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-md w-96 mt-8">
                    <h2 className="text-2xl font-bold mb-6">登录</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="identifier" className="block text-sm font-medium text-gray-700 mb-1">
                                邮箱或手机
                            </label>
                            <input
                                type="text"
                                id="identifier"
                                value={identifier}
                                onChange={(e) => {
                                    setIdentifier(e.target.value);
                                    validateIdentifier(e.target.value);
                                }}
                                className={`w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500`}
                                placeholder="请输入邮箱或手机"
                                required
                            />
                            {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
                        </div>
                        <div className="mb-6">
                            <div className="flex justify-between items-center mb-1">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    密码
                                </label>
                                <a href="#" className="text-sm text-blue-600 hover:underline">
                                    忘记密码?
                                </a>
                            </div>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                placeholder="输入密码"
                                required
                            />
                        </div>
                        <div className="flex items-center mb-6">
                            <input
                                type="checkbox"
                                id="autoRegister"
                                checked={autoRegister}
                                onChange={() => setAutoRegister(!autoRegister)}
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            <label htmlFor="autoRegister" className="ml-2 block text-sm text-gray-900">
                                未注册账号会先注册
                            </label>
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                type="submit"
                                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                登录
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                title="确认登录"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="继续登录"
                cancelText="取消"
                centered
            >
                <p>未勾选 "未注册账号会先注册"，是否继续登录？</p>
            </Modal>
        </div>
    );
};

export default FlexibleSignInPage;
