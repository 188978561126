import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import './QuestionList.css';
import PeiLianHeader from "../headers/PeiLianHeader";
import PeiXunHeader from "../headers/PeiXunHeader";

function QuestionList() {
    const [questions, setQuestions] = useState([]);
    // const [ApiBaseUrl] = useState("http://localhost:8080");
    const ApiBaseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(ApiBaseUrl+"/api/peixun/questions")
            .then(res => res.json())
            .then(data => setQuestions(data));
    }, []);

    const data = React.useMemo(() => questions, [questions]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Question',
                accessor: 'question',
            },
            {
                Header: 'Answer',
                accessor: 'answer',
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className="table-container">
            <PeiXunHeader></PeiXunHeader>



            <table {...getTableProps()} className="table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default QuestionList;
