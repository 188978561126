
import React, { useState } from 'react';
import { MessageCircle } from 'lucide-react';

// 模拟数据
const ttsVoices = [
    { id: 'en-US-1', label: 'English (US) - Female' },
    { id: 'en-US-2', label: 'English (US) - Male' },
    { id: 'en-GB-1', label: 'English (UK) - Female' },
    { id: 'en-GB-2', label: 'English (UK) - Male' },
];

const Field = ({ label, children }) => (
    <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
        {children}
    </div>
);

const Select = ({ onChange, icon, children }) => (
    <div className="relative">
        <select
            onChange={onChange}
            className="block appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
            {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
            {icon}
        </div>
    </div>
);

const VoiceSelect = ({ onSelect }) => {
    const [selectedVoice, setSelectedVoice] = useState(ttsVoices[0]);

    const handleChange = (e) => {
        const newVoice = ttsVoices.find(voice => voice.id === e.target.value);
        setSelectedVoice(newVoice);
        onSelect(newVoice);
    };

    return (
        <Field label="Voice:">
            <Select onChange={handleChange} icon={<MessageCircle size={24} />}>
                {ttsVoices.map((voice) => (
                    <option key={voice.id} value={voice.id}>
                        {voice.label}
                    </option>
                ))}
            </Select>
        </Field>
    );
};

export default VoiceSelect;