import React, { useState } from 'react';
import ModelSelect from './ModelSelect';
import VoiceSelect from './VoiceSelect';
import MicrophoneSelect from './MicrophoneSelect';
import {useNavigate} from "react-router-dom";
import PeiLianHeader from "../../headers/PeiLianHeader";

const Configuration = () => {
    const [joinMuted, setJoinMuted] = useState(false);
    const navigate = useNavigate();
    const [selectedModel, setSelectedModel] = useState('');
    const defaultModel = 'LLAMA-3.1-8B-INSTANT';

    const handleModelSelect = (model) => {
        setSelectedModel(model);
        console.log('Selected model:', model);
    };

    const handleVoiceSelect = (voice) => {
        console.log('Selected voice:', voice);
    };

    const handleStart = () => {
        const modelToUse = selectedModel || defaultModel;

        navigate('/peilian/chatStream', { state: { model: modelToUse } });
        console.log('Start button clicked');
    };

    return (
        <div className="min-h-screen flex flex-col">
            <PeiLianHeader />
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
                <h1 className="text-2xl font-bold mb-4 text-center">配置</h1>
                <p className="text-gray-600 mb-6 text-center">
                    请在下面配置您的设备和模型
                </p>

                <div className="mb-6 bg-blue-50 p-4 rounded-lg flex items-start">
                    <span className="text-blue-500 mr-2">ℹ️</span>
                    <p className="text-sm text-blue-700">
                        最好在安静的环境下。
                    </p>
                </div>

                <MicrophoneSelect />
                <ModelSelect onSelect={handleModelSelect} />
                <VoiceSelect onSelect={handleVoiceSelect} />

                <div className="flex items-center justify-between mb-6">
                    <span className="text-sm font-medium text-gray-700">Join with mic muted</span>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={joinMuted}
                            onChange={() => setJoinMuted(!joinMuted)}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                </div>

                <button
                    onClick={handleStart}
                    className="w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                >
                    Start
                </button>
            </div>
        </div>
        </div>
    );
};

export default Configuration;