import React from 'react';

// import PptViewer from './PptViewer';
import PdfViewer from "./PdfViewer";

const FileViewer = ({ file }) => {
    const getFileType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return extension;
    };

    const fileType = getFileType(file.url);

    switch (fileType) {
        case 'pdf':
            return <PdfViewer file={file.url} />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return <img src={file.url} alt={file.name} className="w-full h-auto" />;
        // case 'pptx':
        //     return <PptViewer file={file.url} />;
        default:
            return <p>无法展示此文件类型: {file.name}</p>;
    }
};

export default FileViewer;
