import React, { useState } from "react";
import { motion } from "framer-motion";
import HomeHeader from "../headers/HomeHeader";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.name.trim()) errors.name = "姓名是必填项";
        if (!formData.email.trim()) {
            errors.email = "邮箱是必填项";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "邮箱格式不正确";
        }
        if (!formData.message.trim()) errors.message = "消息是必填项";
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            // 在这里处理表单提交逻辑
            console.log("表单提交:", formData);
            alert("消息已发送！我们会尽快回复您。");
            setFormData({ name: '', email: '', message: '' });
        } else {
            setFormErrors(errors);
        }
    };

    return (
        <div className="contact-page-container">
            <div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800 text-white">
                <HomeHeader />
                <header className="bg-gradient-to-r from-cyan-500 to-blue-500 py-16">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                        <motion.h1
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className="text-5xl font-bold mb-4 text-left"
                        >
                            联系我们
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="text-xl text-left"
                        >
                            我们期待听到您的声音
                        </motion.p>
                    </div>
                </header>
            </div>
            <div className="bg-white py-16">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-3xl font-bold mb-6 text-gray-800">给我们留言</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2">姓名</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    {formErrors.name && <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-700 font-bold mb-2">邮箱</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                    {formErrors.email && <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>}
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="message" className="block text-gray-700 font-bold mb-2">消息</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    ></textarea>
                                    {formErrors.message && <p className="text-red-500 text-sm mt-1">{formErrors.message}</p>}
                                </div>
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                                    发送消息
                                </button>
                            </form>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <h2 className="text-3xl font-bold mb-6 text-gray-800">联系方式</h2>
                            <div className="mb-4">
                                <h3 className="text-xl font-semibold mb-2 text-gray-700">地址</h3>
                                <p className="text-gray-600">上海市普陀区中山北路3663号</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="text-xl font-semibold mb-2 text-gray-700">电话</h3>
                                <p className="text-gray-600">+86 10 1234 5678</p>
                            </div>
                            <div className="mb-4">
                                <h3 className="text-xl font-semibold mb-2 text-gray-700">邮箱</h3>
                                <p className="text-gray-600">info@example.com</p>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold mb-2 text-gray-700">工作时间</h3>
                                <p className="text-gray-600">周一至周五: 9:30 - 18:00</p>
                                <p className="text-gray-600">周六、周日: 休息</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
            <footer className="bg-gray-100 py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-600">&copy; 2024 索塔沃科技有限公司. All rights reserved.</p>
                    <p className="mt-2 text-gray-700">一起探索AI的无限可能</p>
                </div>
            </footer>
        </div>
    );
};

export default ContactUs;