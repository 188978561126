import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, message, Dropdown, Menu } from 'antd';
import { PlusOutlined, RobotOutlined, EllipsisOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import './RobotList.css';
import PeiLianHeader from "../headers/PeiLianHeader";

const RobotList = () => {
    const [robots, setRobots] = useState([]);
    const navigate = useNavigate();
    // const [ApiBaseUrl] = useState("http://localhost:8080");
    const ApiBaseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchRobots = async () => {
            try {
                let url = `${ApiBaseUrl}/api/peilian/robots`;
                console.log(url);
                const response = await axios.get(url);
                console.log(response);
                setRobots(response.data.robots);
            } catch (error) {
                message.error('获取机器人列表失败');
            }
        };
        fetchRobots();
    }, [ApiBaseUrl]);

    const handleCreateRobot = () => {
        navigate('/peilian/robots/create');
    };

    const handleRobotClick = (id) => {
        navigate(`/peilian/robots/${id}`);
    };

    const handleEditRobot = (id) => {
        navigate(`/peilian/robots/${id}/configuration`);
    };

    const menu = (robotId) => (
        <Menu onClick={(e) => e.domEvent.stopPropagation()}>
            <Menu.Item
                key="edit"
                onClick={() => handleEditRobot(robotId)}
                icon={<EditOutlined />}
            >
                编辑
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="robot-list">
            <PeiLianHeader />
            <div className="robot-list-content">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card hoverable className="create-robot" onClick={handleCreateRobot}>
                            <PlusOutlined className="create-robot-icon" />
                            <div className="create-robot-content">
                                <h3>创建机器人</h3>
                                <p>创建一个新的AI机器人</p>
                            </div>
                        </Card>
                    </Col>
                    {robots.map(robot => (
                        <Col xs={24} sm={12} md={8} lg={6} key={robot.robot_id}>
                            <Card
                                hoverable
                                className="robot-card"
                                onClick={() => handleRobotClick(robot.robot_id)}
                                extra={
                                    <Dropdown
                                        overlay={menu(robot.robot_id)}
                                        trigger={['click']}
                                    >
                                        <EllipsisOutlined
                                            className="robot-card-menu"
                                            onClick={(e) => e.stopPropagation()} // 阻止事件传播
                                        />
                                    </Dropdown>
                                }
                            >
                                <RobotOutlined className="robot-card-icon" />
                                <div className="robot-card-content">
                                    <h3>{robot.name}</h3>
                                    <p className="robot-description">{robot.description}</p>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default RobotList;
