import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PeiLianHeader from "../headers/PeiLianHeader";

const CreateRobot = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const baseUrlApi = "http://localhost:8080";
    const baseUrlApi = process.env.REACT_APP_API_URL;

    const onFinish = async (values) => {
        setLoading(true);
        try {
            console.log(values)
            const response = await axios.post(baseUrlApi+'/api/peilian/robots/create', values);
            message.success('机器人创建成功');
            navigate('/peilian/robots'); // 假设创建成功后跳转到机器人列表页
        } catch (error) {
            console.error('创建机器人失败:', error);
            message.error('创建机器人失败，请重试');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <PeiLianHeader />

            <div style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
            <h1>创建新机器人</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label="机器人名称"
                    rules={[{ required: true, message: '请输入机器人名称' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="描述"
                    rules={[{ required: true, message: '请输入机器人描述' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    name="api_key"
                    label="API密钥"
                    rules={[{ required: true, message: '请输入API密钥' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        创建机器人
                    </Button>
                </Form.Item>
            </Form>
        </div>
            </div>
    );
};

export default CreateRobot;