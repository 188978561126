import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, message, Spin } from 'antd';
import axios from 'axios';
import PeiLianHeader from "../headers/PeiLianHeader";

const RobotConfiguration = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // const baseUrlApi = "http://localhost:8080";
    const baseUrlApi = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchRobotData = async () => {
            try {
                const response = await axios.get(baseUrlApi+`/api/peilian/robots/${id}`);
                form.setFieldsValue(response.data);
                setLoading(false);
            } catch (error) {
                console.error('获取机器人数据失败:', error);
                message.error('获取机器人数据失败');
                setLoading(false);
            }
        };

        fetchRobotData();
    }, [id, form]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await axios.post(baseUrlApi+`/api/peilian/robots/${id}`, values);
            message.success('机器人配置更新成功');
            navigate('/peilian/robots');
        } catch (error) {
            console.error('更新机器人配置失败:', error);
            message.error('更新机器人配置失败');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" className="flex justify-center items-center h-screen" />;
    }

    return (
        <div>
            <PeiLianHeader/>
            <div style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                    <h1 className="text-2xl font-bold mb-6 text-center">配置机器人</h1>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="name"
                            label="机器人名称"
                            rules={[{ required: true, message: '请输入机器人名称' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="描述"
                            rules={[{ required: true, message: '请输入机器人描述' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            name="api_key"
                            label="API Key"
                            rules={[{ required: true, message: '请输入 API Key' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item className="flex justify-center">
                            <Button type="primary" htmlType="submit" className="mr-4">
                                更新配置
                            </Button>
                            <Button onClick={() => navigate('/peilian/robots')}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            </div>
    );
};

export default RobotConfiguration;