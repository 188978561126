import React from 'react';
// import { Button } from '@/components/ui/button';

const XiaogeAssistantPage = () => {
    return (
        <div className="flex flex-col md:flex-row items-center justify-center min-h-[500px] h-[500px] bg-gradient-to-r from-purple-100 to-pink-100 p-8">
            <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0 text-left">
                <h1 className="text-4xl font-bold mb-4 text-gray-800">小格助手</h1>
                <p className="text-lg mb-6 text-gray-600">
                    小格是一款先进的AI智能体,能够理解和回应各种复杂的查询。无论是日常对话、专业咨询还是创意灵感,小格都能提供智能、精准的答案。
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-600">
                    <li>24/7全天候服务</li>
                    <li>多领域知识库</li>
                    <li>自然语言处理能力强</li>
                    <li>持续学习和更新</li>
                </ul>
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    立即尝试
                </button>
            </div>
            <div className="md:w-1/2 text-center md:text-right h-full">
                <img
                    src="/home/works/xiaogenew.png"
                    alt="小格助手"
                    className="rounded-lg shadow-lg mx-auto md:ml-auto md:mr-0 h-full object-contain"
                />
            </div>
        </div>
    );
};

export default XiaogeAssistantPage;
