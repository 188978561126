import React from 'react';
import './DefaultPage.css';
import { useNavigate } from "react-router-dom";
import PeiLianHeader from "../headers/PeiLianHeader";
const DefaultPage = () => {
    const navigate = useNavigate();

    function changePage() {
        navigate('/peilian/robots');
    }

    return (
        <div className="page-wrapper">
            <PeiLianHeader />
            <div className="content-wrapper">
                <div className="background-container">
                    <img src='/peilian/background.jpg' alt="背景图片" className="background-image" />
                    <div className="content">
                        <div className="title">让企业员工培训更科学更高效</div>
                        <div className="description">
                            索塔沃智能陪练，专为企业各服务场景打造，融合大模型及多项AI技术，
                            助力员工提升专业服务水平提高培训师课程开发效率，为企业创造卓越价值。
                        </div>
                        <button className="button" onClick={changePage}>立即体验</button>
                    </div>
                </div>
            </div>
            <footer>
                &copy; 2024 索塔沃科技有限公司. All rights reserved.
            </footer>
        </div>
    );
}

export default DefaultPage;
