import React from "react";
import {useNavigate} from "react-router-dom";

const HomeHeader = () => {
    const navigate = useNavigate();

    return (
        <header>
            <div className="logo" onClick={()=>{  navigate('/'); }}>上海索塔沃科技有限公司</div>
            <nav>
                <ul>
                    <li><a href="/">首页</a></li>
                    <li><a href="/about">关于我们</a></li>
                    <li><a href="/works">产品介绍</a></li>
                    {/*<li><a href="/services">服务领域</a></li>*/}
                    {/*<li><a href="/news">新闻动态</a></li>*/}
                    <li><a href="/team">团队风采</a></li>
                    <li><a href="/jobs">诚聘英才</a></li>
                    <li><a href="/contact">联系我们</a></li>
                </ul>
            </nav>
        </header>
    );
};
export default HomeHeader;