import React, { useState } from 'react';
import { Package } from 'lucide-react';

// 模拟数据
const llmModels = [
    { id: 'LLAMA-3.1-8B-INSTANT', label: 'LLAMA-3.1-8B-INSTANT' },
    { id: 'gpt-4', label: 'GPT-4' },
    { id: 'llama-7b', label: 'LLaMA 7B' },
    { id: 'llama-13b', label: 'LLaMA 13B' },
];

const Field = ({ label, children }) => (
    <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
        {children}
    </div>
);

const Select = ({ onChange, icon, children }) => (
    <div className="relative">
        <select
            onChange={onChange}
            className="block appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
            {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
            {icon}
        </div>
    </div>
);

const ModelSelect = ({ onSelect }) => {
    const [selectedModel, setSelectedModel] = useState(llmModels[0].id);

    const handleChange = (e) => {
        const newModel = e.target.value;
        setSelectedModel(newModel);
        onSelect(newModel);
    };

    return (
        <Field label="大语言模型:">
            <Select onChange={handleChange} icon={<Package size={24} />}>
                {llmModels.map((model) => (
                    <option key={model.id} value={model.id}>
                        {model.label}
                    </option>
                ))}
            </Select>
        </Field>
    );
};

export default ModelSelect;