import React, { useState } from 'react';
import { motion } from 'framer-motion';
import HomeHeader from "../../headers/HomeHeader";
import FileViewer from './FileViewer';

const Works = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const files = [
        { id: 1, name: "小格pdf", url: "/pdf1.pdf" },
        { id: 2, name: "作品集2.pptx", url: "/path/to/portfolio2.pptx" },
        { id: 3, name: "小格图", url: "/home/works/xiaoge.png" },
        // 添加更多文件，包括 PPT 或图像文件
    ];

    return (
        <div className="pdf-portfolio-container">
            <div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800 text-white">
                <HomeHeader />
                <header className="bg-gradient-to-r from-cyan-500 to-blue-500 py-16">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                        <motion.h1
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className="text-5xl font-bold mb-4 text-left"
                        >
                            产品集展示
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="text-xl text-left"
                        >
                            浏览我们的产品集
                        </motion.p>
                    </div>
                </header>
            </div>
            <div className="bg-white py-16">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="md:col-span-1">
                            <h2 className="text-2xl font-bold mb-4 text-gray-800">产品列表</h2>
                            <ul className="space-y-2">
                                {files.map((file) => (
                                    <li key={file.id}>
                                        <button
                                            onClick={() => setSelectedFile(file)}
                                            className="w-full text-left px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-300"
                                        >
                                            {file.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="md:col-span-2">
                            {selectedFile ? (
                                <FileViewer file={selectedFile} />
                            ) : (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-gray-500 text-xl">请从左侧选择一个产品进行预览</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg-gray-100 py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-600">&copy; 2024 索塔沃科技有限公司. All rights reserved.</p>
                    <p className="mt-2 text-gray-700">一起探索AI的无限可能</p>
                </div>
            </footer>
        </div>
    );
};

export default Works;
