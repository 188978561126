import '../App.css';
import '@chatui/core/es/styles/index.less';
import Chat, { Bubble, useMessages, Tag, Modal, Input, toast } from '@chatui/core';
import '@chatui/core/dist/index.css';
import axios from "axios";
import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { message, Modal as AntdModal, Dropdown, Menu } from "antd";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

// 注册 Chart.js 组件
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const initialMessages = [
    {
        type: 'text',
        content: { text: '你好我想咨询结婚业务。' },
        user: { avatar: '/peilian/xiaoge.png' },
    },
];

const defaultQuickReplies = [
    {
        icon: "keyboard-circle",
        name: '分析对话',
        isNew: true
    },
];

const RadarChart = ({ data, onClose }) => {
    const chartData = {
        labels: ['礼貌性', '专业性', '违禁词'],
        datasets: [
            {
                label: '评分',
                data: [data.politeness.score, data.professionalism.score, data.forbidden.score],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <div style={{
            position: 'absolute',
            top: '20%', // 调整位置
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            zIndex: 1000, // 确保图表在所有内容之上
        }}>
            <button
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    background: 'transparent',
                    border: 'none',
                    fontSize: 16,
                    cursor: 'pointer'
                }}
            >
                ×
            </button>
            <Radar data={chartData} options={options} />
            <div style={{ marginTop: '20px',color: 'black'}}>
                <h4>打分原因：</h4>
                <p><strong>礼貌性:</strong> {data.politeness.reason}</p>
                <p><strong>专业性:</strong> {data.professionalism.reason}</p>
                <p><strong>违禁词:</strong> {data.forbidden.reason}</p>
            </div>
        </div>
    );
};

export default function PeiLianHome() {
    const baseUrlApi = process.env.REACT_APP_API_URL;

    const [batch, setBatch] = useState("");
    const { messages, appendMsg, setTyping, resetList } = useMessages(initialMessages);
    const [open, setOpen] = useState(false);
    const [sessionopen, setSessionOpen] = useState(false);
    // const [baseUrlApi, setApiValue] = useState("http://localhost:8080");
    // const [baseUrlApi, setApiValue] = useState("http://172.23.142.200:9398");

    const [chatMessage, setChatMessage] = useState([]);
    const [history, setHistory] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState(null);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [newSessionTitle, setNewSessionTitle] = useState(""); // 新增的状态
    const [showHistory, setShowHistory] = useState(false); // 控制历史显示的状态
    const [showRadar, setShowRadar] = useState(false); // 控制雷达图显示的状态
    const mediaRecorder = useRef(null);
    const audioChunksRef = useRef([]);
    const [isRecording, setIsRecording] = useState(false);
    const mediaStream = useRef(null);
    let stopType = useRef(0);
    const [chartData, setChartData] = useState(null);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStream.current = stream;
            mediaRecorder.current = new MediaRecorder(stream);

            mediaRecorder.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                console.log('录音 Blob 大小:', audioBlob.size);
                console.log("stop type",stopType.current)
                // 处理音频
                if (stopType.current === 1) {
                    handleAudio(audioBlob);
                }
                // 清空音频块
                audioChunksRef.current = [];
                setIsRecording(false);
                stopMediaStream();
            };

            mediaRecorder.current.start();
            setIsRecording(true);
            stopType.current = 0;
            console.log('开始录音');
        } catch (error) {
            console.error('录音错误:', error);
            setIsRecording(false);
            stopMediaStream();
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current && isRecording) {
            stopType.current = 1;
            mediaRecorder.current.stop();
            console.log('停止录音');
            console.log(stopType.current)
        } else {
            console.error('录音器未初始化或未在录音');
        }
    };

    const cancelRecording = () => {
        if (mediaRecorder.current && isRecording) {
            stopType.current = 2;
            mediaRecorder.current.stop();
            audioChunksRef.current = [];
            setIsRecording(false);
            stopMediaStream();
            console.log('取消录音');
        }
    };

    const stopMediaStream = () => {
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach(track => track.stop());
            mediaStream.current = null;
        }
    };

    const handleAudio = async (blob) => {
        console.log('处理音频', blob);
        if (blob.size === 0) {
            console.error('录音文件大小为 0，无法处理');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];
            console.log('Base64 编码:', base64String);

            const voiceData = {
                Len: blob.size,
                Speech: base64String,
            };

            // 将VoiceData结构体编码为JSON
            const jsonData = JSON.stringify(voiceData);

            // 创建HTTP请求
            const url = 'http://localhost:8080/audio/recognize'; // 替换为你的实际API端点
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            })
                .then(response => response.json())
                .then(result => {
                    console.log('识别结果:', result);
                    const audioUrl = URL.createObjectURL(blob);
                    // 将音频文件发送到聊天窗口
                    if (result !=="") {
                        handleSend("voice", result, audioUrl)
                    }else{

                    }
                })
                .catch(error => {
                    console.error('语音识别失败:', error);
                });
        };
        reader.readAsDataURL(blob);

    };

    useEffect(() => {
        const sessionId = uuidv4();
        setCurrentSessionId(sessionId);
        fetchSessionHistory();
    }, []);

    const fetchSessionHistory = async () => {
        try {
            const sessionResponse = await axios.get(`${baseUrlApi}/sessionList/get`);
            const formattedSessions = sessionResponse.data.sessions.map(session => ({
                id: session.SessionID,
                messageCount: session.messageCount,
                createdAt: session.createdAt,
                ...session,
            }));
            setHistory(formattedSessions);
        } catch (error) {
            console.error('Error fetching session history:', error);
        }
    };

    async function saveMessage(sessionId, message) {
        try {
            await axios.post(`${baseUrlApi}/saveMessage`, {
                sessionId,
                message,
            });
        } catch (error) {
            console.error('Error saving message:', error);
        }
    }

    async function addSession(sessionId, title) { // 修改函数签名
        try {
            await axios.post(`${baseUrlApi}/sessionList/add`, {
                sessionId,
                title // 添加标题
            });
        } catch (error) {
            console.error('Error adding session:', error);
        }
    }

    async function loadSessionMessages(sessionId) {
        try {
            const response = await axios.get(`${baseUrlApi}/getMessages/${sessionId}`);
            const messages = response.data.messages;
            resetList();
            messages.forEach(msg => appendMsg(msg));
            setChatMessage(messages);
        } catch (error) {
            console.error('Error loading session messages:', error);
        }
    }

    async function deleteSession(sessionId) {
        try {
            await axios.post(`${baseUrlApi}/sessionList/delete`, { sessionId });
            fetchSessionHistory(); // Refresh the session list after deletion
            if (sessionId === selectedSessionId) {
                resetList(initialMessages);
                setSelectedSessionId(null);
            }
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    }

    function handleSetValue(val) {
        setBatch(val);
    }

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    function handleSessionClose() {
        setSessionOpen(false);
    }

    function handleConfirm() {
        if (isNaN(batch) || parseInt(batch) < 1 || parseInt(batch) > 10 || batch === "") {
            toast.fail("请输入3-10之间的数字");
            return;
        }
        localStorage.setItem('batch', batch);
        toast.success("操作成功");
        setOpen(false);
    }

    if (localStorage.getItem('clickFlag')) {
        defaultQuickReplies[0].isNew = false;
    } else {
        defaultQuickReplies[0].isNew = true;
    }
    function inputTypeChange(type){
        console.log(type)
    }
    function handleSend(type, val, url) {
        console.log("发送")
        console.log(type,val)
        if (messages.length === 1 && currentSessionId !== "") {
            if (newSessionTitle===""){
                setNewSessionTitle ("陪练")
            }
            const newSession = {
                id: currentSessionId,
                title: newSessionTitle===""?"陪练":newSessionTitle // 添加标题
            };
            setHistory([...history, newSession]);
            addSession(currentSessionId, newSessionTitle); // 传递标题
        }

        let userMessage = {

        }
        if (type === 'text' && val.trim()) {
            userMessage = {
                type: 'text',
                content: { text: val },
                position: 'right',
                hasTime: 'true',
                user: { avatar: '/peilian/user.jpg' },
                withComment: false,
                role:'user',
            };
        }else{
            userMessage = {
                type: 'audio',
                content: { url: url,text:val },
                position: 'right',
                hasTime: 'true',
                user: { avatar: '/peilian/user.jpg' },
                role:'user',
            }
        }
        // if (count % batch !== 0) {
        appendMsg(userMessage);
        saveMessage(currentSessionId, userMessage);
        // }

        axios.post(
            baseUrlApi + '/agent/query',
            {
                "query": val,
                "session_id":currentSessionId,
            },
        )
            .then((res) => {
                const answer = res.data.msg.data.answer;
                let systemMessage = {
                    type: 'text',
                    content: { text: answer },
                    user: { avatar: '/peilian/xiaoge.png' },
                    hasTime: 'true',
                    withComment: false,
                    role:'system'
                };

                const newMessages = [
                    ...chatMessage,
                    userMessage,
                    { content: val, role: 'user' },
                    { content: answer, role: 'system' },
                ];
                console.log(newMessages)
                setChatMessage(newMessages);
                appendMsg(systemMessage);
                saveMessage(currentSessionId, systemMessage);
            })
            .catch((err) => {
                console.log(err);
                toast.fail("出错啦！请稍后再试");
            });

        setTyping(true);

    }

    function handleQuickReplyClick(item) {
        // handleSend('text', item.name);
        if (item.name==="分析对话"){
            handleAnalysis()
        }

    }
    function handleAnalysis(){
        console.log(messages.length)
        if (messages.length <=1){
            toast.fail("未开始对话");
        }else{
            axios.post(
                baseUrlApi + '/agent/analysis',
                {
                    "session_id":currentSessionId,
                },
            )
                .then((res) => {
                    const answer = res.data.msg.Analysis;
                    console.log(answer)
                    setChartData(answer);
                    setShowRadar(true); // 设置雷达图显示为 true
                })
        }
    }

    function renderMessageContent(msg) {
        const { type, withComment, polite, professional, ban, content } = msg;

        if (type === 'text') {
            if (withComment) {
                return (
                    <div>
                        <Bubble content={content.text}>
                            <br />
                            <Tag>礼貌性:{polite}</Tag> <Tag>专业性:{professional}</Tag> <Tag>违禁词:{ban}</Tag>
                        </Bubble>
                        <br />
                    </div>
                );
            } else {
                return (
                    <Bubble content={content.text} />
                );
            }
        }  else if (msg.type === 'audio') {
            return <audio controls src={content.url} />;
        }
        else if (type === 'image') {
            return (
                <Bubble type="image">
                    <img src={content.picUrl} alt="" />
                </Bubble>
            );
        }
    }

    function setOption() {
        handleOpen();
    }

    function clear() {
        window.location.reload();
    }

    function newSession() {
        const sessionId = uuidv4();
        setCurrentSessionId(sessionId);
        resetList(initialMessages);
        setChatMessage([]);
    }

    function loadSession(sessionId) {
        setCurrentSessionId(sessionId);
        setSelectedSessionId(sessionId);
        loadSessionMessages(sessionId);
    }

    function confirmDeleteSession(sessionId) {
        AntdModal.confirm({
            title: '确认删除会话?',
            content: '此操作将永久删除该会话记录，无法恢复。',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                deleteSession(sessionId);
            },
        });
    }

    const menu = (sessionId) => (
        <Menu>
            <Menu.Item key="delete" onClick={() => confirmDeleteSession(sessionId)}>
                删除
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className='head'>
                <button className='history-toggle-btn' onClick={() => setShowHistory(!showHistory)}>
                    <i className="fas fa-history"></i>
                </button>
                <div className='head-set' onClick={() => setOption()}></div>
                <div className='head-title'>格莱美陪练助手</div>
                <div className='head-clear' onClick={() => clear()}><img src={process.env.PUBLIC_URL + '/clear.png'} alt="清空" />
                </div>
            </div>
            <div className='home-container'>
                {showRadar && chartData && <RadarChart data={chartData} onClose={() => setShowRadar(false)} />}
                {showHistory && (<div className='home-history'>
                        <button className='new-session-btn' onClick={() => setSessionOpen(true)}>新建会话</button>
                        <h3>会话历史</h3>
                        {history.map((session) => (
                            <li key={session.id} className={selectedSessionId === session.id ? 'selected-session' : ''}>
                                <Dropdown overlay={menu(session.id)} trigger={['contextMenu']}>
                                    <p onClick={() => loadSession(session.id)}>会话 {session.Title}</p>
                                </Dropdown>
                            </li>
                        ))}
                    </div>
                )}
                <div className={showHistory === true?'chat':'chatfull'}>
                    <Chat
                        placeholder="有问题尽管问我~"
                        messages={messages}
                        renderMessageContent={renderMessageContent}
                        quickReplies={defaultQuickReplies}
                        onQuickReplyClick={handleQuickReplyClick}
                        onSend={handleSend}
                        onInputTypeChange={inputTypeChange}
                        recorder={{ canRecord: true,
                            onStart: startRecording,
                            onEnd: stopRecording,
                            onCancel: cancelRecording,
                        }}
                    />
                </div>
            </div>
            <Modal
                active={open}
                title="设置对话轮数"
                showClose={false}
                onClose={handleClose}
                actions={[
                    {
                        label: '确认',
                        color: 'primary',
                        onClick: handleConfirm,
                    },
                    {
                        label: '取消',
                        onClick: handleClose,
                    },
                ]}
            >
                <div>
                    <p><span className='requird-span'>*</span>对话轮数</p>
                    <Input value={batch} onChange={val => handleSetValue(val)} placeholder="请输入对话轮数（3-10）" />
                </div>
            </Modal>
            <AntdModal
                title="创建新会话"
                visible={sessionopen}
                onOk={() => {
                    newSession();
                    handleSessionClose();
                }}
                onCancel={handleSessionClose}
            >
                <div>
                    <p>会话标题</p>
                    <Input value={newSessionTitle} onChange={val => setNewSessionTitle(val)} placeholder="请输入会话标题" />
                </div>
            </AntdModal>
        </>
    );
}
