import React, { useState } from 'react';
import './CreateQuestion.css';
import PeiLianHeader from "../headers/PeiLianHeader";
import PeiXunHeader from "../headers/PeiXunHeader";

function CreateQuestion() {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    // const [ApiBaseUrl] = useState("http://localhost:8080");
    const ApiBaseUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(ApiBaseUrl+"/api/peixun/questions/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ question, answer })
        })
            .then(() => {
                setQuestion('');
                setAnswer('');
            });
    };

    return (
        <div className="form-container">
            <PeiXunHeader></PeiXunHeader>


            <h1>Create Question</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Question:</label>
                    <input
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </div>
                <div>
                    <label>Answer:</label>
                    <input
                        type="text"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </div>
                <button type="submit">Add Question</button>
            </form>
        </div>
    );
}

export default CreateQuestion;
