import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Chat, { Bubble, useMessages, Tag, Modal, Input, toast } from '@chatui/core';
import { message as antMessage, Modal as AntdModal, Dropdown, Menu } from "antd";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import '@chatui/core/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import './RobotChat.css';
import PeiLianHeader from "../headers/PeiLianHeader";
import { SoundOutlined, PauseOutlined } from '@ant-design/icons';


ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


const initialMessages = [
    {
        type: 'text',
        content: { text: '你好我想咨询结婚业务。' },
        user: { avatar: '/peilian/xiaoge.png' },
    },
];

const defaultQuickReplies = [
    {
        icon: "keyboard-circle",
        name: '分析对话',
        isNew: true
    },
];

const defaultConfig = {
    llm: {
        model: 1,
        messages: [
            {
                role: "system",
                content:
                    "You are Chatbot, a friendly, helpful robot. Your output will be converted to audio so don't include special characters other than '!' or '?' in your answers. Respond to what the user said in a creative and helpful way, but keep your responses brief. Start by saying hello.",
                //composeSystemPrompt(defaultLanguage),
            },
        ],
    },
    tts: {
        voice: 1,
    },
};

//
// const voiceClient = new VoiceClient({
//     baseUrl: "http://localhost:8080",
//     enableMic: true,
//     config: defaultConfig,
// });

// export const VoiceLayout = () => {
//
//     return (
//         <VoiceClientProvider voiceClient={voiceClient}>
//
//         </VoiceClientProvider>
//     );
// };


// 自定义音频消息组件
const AudioMessage = ({ url, text, duration }) => {
    // console.log("持续时间111",duration)
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioDuration, setAudioDuration] = useState(duration || 0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => setCurrentTime(audio.currentTime);
        const updateDuration = () => setAudioDuration(audio.duration);

        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('loadedmetadata', updateDuration);

        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('loadedmetadata', updateDuration);
        };
    }, []);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const progress = (currentTime / audioDuration) * 100 || 0;

    return (
        <div className="audio-message">
            <div className="audio-content" onClick={togglePlay}>
                {isPlaying ? (
                    <PauseOutlined className="audio-icon" />
                ) : (
                    <SoundOutlined className="audio-icon" />
                )}
                <div className="audio-progress-container">
                    <div className="audio-progress-bar" style={{ width: `${progress}%` }}></div>
                    <div className="audio-wave">
                        {Array(3).fill().map((_, i) => (
                            <span key={i} className="wave-bar" style={{ animationPlayState: isPlaying ? 'running' : 'paused' }}></span>
                        ))}
                    </div>
                </div>
                <span className="audio-duration">
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>
            </div>
            {text && <p className="audio-text">{text}</p>}
            <audio
                ref={audioRef}
                src={url}
                onEnded={() => setIsPlaying(false)}
                onLoadedMetadata={() => setAudioDuration(audioRef.current.duration)}
            />
        </div>
    );
};

const RadarChart = ({ data, onClose }) => {
    const chartData = {
        labels: ['礼貌性', '专业性', '违禁词'],
        datasets: [
            {
                label: '评分',
                data: [data.politeness.score, data.professionalism.score, data.forbidden.score],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <div style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            zIndex: 1000,
        }}>
            <button
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    background: 'transparent',
                    border: 'none',
                    fontSize: 16,
                    cursor: 'pointer'
                }}
            >
                ×
            </button>
            <Radar data={chartData} options={options} />
            <div style={{ marginTop: '20px', color: 'black'}}>
                <h4>打分原因：</h4>
                <p><strong>礼貌性:</strong> {data.politeness.reason}</p>
                <p><strong>专业性:</strong> {data.professionalism.reason}</p>
                <p><strong>违禁词:</strong> {data.forbidden.reason}</p>
            </div>
        </div>
    );
};

const RobotChat = () => {
    const { id: robotId } = useParams();
    const navigate = useNavigate();
    // const baseUrlApi = process.env.REACT_APP_API_URL;
    // const baseUrlApi = "http://localhost:8080";
    const baseUrlApi = process.env.REACT_APP_API_URL;

    const [robotName, setRobotName] = useState("加载中...");
    const { messages, appendMsg, setTyping, resetList } = useMessages(initialMessages);
    const [open, setOpen] = useState(false);
    const [sessionopen, setSessionOpen] = useState(false);
    const [chatMessage, setChatMessage] = useState([]);
    const [history, setHistory] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState(null);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [newSessionTitle, setNewSessionTitle] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [showRadar, setShowRadar] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [batch, setBatch] = useState("");

    const mediaRecorder = useRef(null);
    const audioChunksRef = useRef([]);
    const [isRecording, setIsRecording] = useState(false);
    const mediaStream = useRef(null);
    let stopType = useRef(0);
    const durationRef = useRef(0);

    useEffect(() => {
        const sessionId = uuidv4();
        setCurrentSessionId(sessionId);
        fetchRobotDetails();
        fetchSessionHistory();
    }, [robotId]);

    const fetchRobotDetails = async () => {
        try {
            const response = await axios.get(`${baseUrlApi}/api/peilian/robots/${robotId}`);
            setRobotName(response.data.name);
        } catch (error) {
            console.error('Error fetching robot details:', error);
            antMessage.error('获取机器人信息失败');
        }
    };

    const fetchSessionHistory = async () => {
        try {
            const sessionResponse = await axios.get(`${baseUrlApi}/sessionList/get`);
            const formattedSessions = sessionResponse.data.sessions.map(session => ({
                id: session.SessionID,
                messageCount: session.messageCount,
                createdAt: session.createdAt,
                ...session,
            }));
            setHistory(formattedSessions);
        } catch (error) {
            console.error('Error fetching session history:', error);
        }
    };

    async function saveMessage(sessionId, message) {
        try {
            await axios.post(`${baseUrlApi}/api/peilian/saveMessage`, {
                robot_id:robotId,
                session_id:sessionId,
                message:message,
            });
        } catch (error) {
            console.error('Error saving message:', error);
        }
    }

    async function addSession(sessionId, title) {
        try {
            await axios.post(`${baseUrlApi}/sessionList/add`, {
                robotId,
                sessionId,
                title
            });
        } catch (error) {
            console.error('Error adding session:', error);
        }
    }

    async function loadSessionMessages(sessionId) {
        try {
            const response = await axios.get(`${baseUrlApi}/getMessages/${sessionId}`);
            const messages = response.data.messages;
            resetList();
            messages.forEach(msg => appendMsg(msg));
            setChatMessage(messages);
        } catch (error) {
            console.error('Error loading session messages:', error);
        }
    }

    async function deleteSession(sessionId) {
        try {
            await axios.post(`${baseUrlApi}/sessionList/delete`, {  sessionId });
            fetchSessionHistory();
            if (sessionId === selectedSessionId) {
                resetList([]);
                setSelectedSessionId(null);
            }
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    }

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStream.current = stream;
            mediaRecorder.current = new MediaRecorder(stream);

            mediaRecorder.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                console.log('录音 Blob 大小:', audioBlob.size);
                console.log("stop type", stopType.current)
                console.log("时长", durationRef.current);
                // console.log("时长",recordingDuration)
                if (stopType.current === 1) {
                    handleAudio(audioBlob,durationRef.current);
                }
                audioChunksRef.current = [];
                setIsRecording(false);
                stopMediaStream();
            };
            mediaRecorder.current.start();
            setIsRecording(true);
            stopType.current = 0;
            durationRef.current = 0;
            console.log('开始录音');
        } catch (error) {
            console.error('录音错误:', error);
            setIsRecording(false);
            stopMediaStream();
        }
    };

    const stopRecording = (data) => {
        console.log("数据",data.duration)
        const durationInSeconds = data.duration / 1000;
        durationRef.current = Math.floor(durationInSeconds);
        if (mediaRecorder.current && isRecording) {
            stopType.current = 1;
            mediaRecorder.current.stop();
            console.log('停止录音，持续时间:', durationRef.current, '秒');


        } else {
            console.error('录音器未初始化或未在录音');
        }
    };

    const cancelRecording = () => {
        if (mediaRecorder.current && isRecording) {
            stopType.current = 2;
            mediaRecorder.current.stop();
            audioChunksRef.current = [];
            setIsRecording(false);
            stopMediaStream();
            console.log('取消录音');
        }
    };

    const stopMediaStream = () => {
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach(track => track.stop());
            mediaStream.current = null;
        }
    };

    const handleAudio = async (blob, duration) => {
        console.log('处理音频', blob);
        console.log('时间',duration)
        if (blob.size === 0) {
            console.error('录音文件大小为 0，无法处理');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1];
            console.log('Base64 编码:', base64String);

            const voiceData = {
                len: blob.size,
                speech: base64String,
            };
            // const audioUrl = URL.createObjectURL(blob);

            // handleSend("voice", "test结果", audioUrl, duration)

            const jsonData = JSON.stringify(voiceData);

            const url = `${baseUrlApi}/api/peilian/audio/recognize`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: jsonData,
            })
                .then(response => response.json())
                .then(result => {
                    console.log('识别结果:', result);
                    if (result.error ===""){
                        const audioUrl = URL.createObjectURL(blob);

                        if (result !== "") {
                            handleSend("voice", result, audioUrl, duration)
                        } else {
                            // 处理空结果
                            // handleSend("voice", result, audioUrl, duration)
                            antMessage.error('语音为空');
                        }
                    }else{
                        antMessage.error('语音为空');
                    }
                })
                .catch(error => {
                    console.error('语音识别失败:', error);
                });
        };
        reader.readAsDataURL(blob);
    };

    function handleSend(type, val, url,duration) {
        if (messages.length === 0 && currentSessionId) {
            const newSessionTitle = newSessionTitle || robotName;
            const newSession = {
                id: currentSessionId,
                title: newSessionTitle
            };
            setHistory([...history, newSession]);
            addSession(currentSessionId, newSessionTitle);
        }

        let userMessage = type === 'text' ? {
            type: 'text',
            content: { text: val },
            position: 'right',
            hasTime: true,
            user: { avatar: '/peilian/user.jpg' },
            withComment: false,
            role: 'user',
        } : {
            type: 'audio',
            content: { url: url, text: val, duration:duration },
            position: 'right',
            hasTime: true,
            user: { avatar: '/peilian/user.jpg' },
            role: 'user',
        };

        appendMsg(userMessage);
        saveMessage(currentSessionId, userMessage);

        axios.post(`${baseUrlApi}/agent/query`, {
            robot_id:robotId,
            query: val,
            session_id: currentSessionId,
        })
            .then((res) => {
                const answer = res.data.msg.data.answer;
                let systemMessage = {
                    type: 'text',
                    content: { text: answer },
                    user: { avatar: '/peilian/xiaoge.png' },
                    hasTime: true,
                    withComment: false,
                    role: 'system'
                };

                const newMessages = [
                    ...chatMessage,
                    userMessage,
                    { content: val, role: 'user' },
                    { content: answer, role: 'system' },
                ];
                setChatMessage(newMessages);
                appendMsg(systemMessage);
                saveMessage(currentSessionId, systemMessage);
            })
            .catch((err) => {
                console.log(err);
                toast.fail("出错啦！请稍后再试");
            });

        setTyping(true);
    }

    function handleQuickReplyClick(item) {
        if (item.name === "分析对话") {
            handleAnalysis()
        }
    }

    function handleAnalysis() {
        console.log(messages.length)
        if (messages.length <= 1) {
            toast.fail("未开始对话");
        } else {
            axios.post(
                `${baseUrlApi}/agent/analysis`,
                {
                    "robot_id":robotId,
                    "session_id": currentSessionId,
                },
            )
                .then((res) => {
                    const answer = res.data.msg.Analysis;
                    console.log(answer)
                    setChartData(answer);
                    setShowRadar(true);
                })
        }
    }

    function renderMessageContent(msg) {
        const { type, withComment, polite, professional, ban, content } = msg;

        if (type === 'text') {
            if (withComment) {
                return (
                    <div>
                        <Bubble content={content.text}>
                            <br />
                            <Tag>礼貌性:{polite}</Tag> <Tag>专业性:{professional}</Tag> <Tag>违禁词:{ban}</Tag>
                        </Bubble>
                        <br />
                    </div>
                );
            } else {
                return (
                    <Bubble content={content.text} />
                );
            }
        } else if (msg.type === 'audio') {
            return (
            <Bubble>
                <AudioMessage url={content.url} text={content.text} duration={content.duration}/>
            </Bubble>
            )
            // return <audio controls src={content.url} />;
        } else if (type === 'image') {
            return (
                <Bubble type="image">
                    <img src={content.picUrl} alt="" />
                </Bubble>
            );
        }
    }

    function handleSetValue(val) {
        setBatch(val);
    }

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleSessionClose() {
        setSessionOpen(false);
    }

    function handleConfirm() {
        if (isNaN(batch) || parseInt(batch) < 1 || parseInt(batch) > 10 || batch === "") {
            toast.fail("请输入1-10之间的数字");
            return;
        }
        localStorage.setItem('batch', batch);
        toast.success("操作成功");
        setOpen(false);
    }

    function inputTypeChange(type) {
        console.log(type)
    }

    function newSession() {
        const sessionId = uuidv4();
        setCurrentSessionId(sessionId);
        resetList([]);
        setChatMessage([]);
    }

    function loadSession(sessionId) {
        setCurrentSessionId(sessionId);
        setSelectedSessionId(sessionId);
        loadSessionMessages(sessionId);
    }

    function confirmDeleteSession(sessionId) {
        AntdModal.confirm({
            title: '确认删除会话?',
            content: '此操作将永久删除该会话记录，无法恢复。',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                deleteSession(sessionId);
            },
        });
    }

    const menu = (sessionId) => (
        <Menu>
            <Menu.Item key="delete" onClick={() => confirmDeleteSession(sessionId)}>
                删除
            </Menu.Item>
        </Menu>
    );

    const defaultQuickReplies = [
        {
            icon: "keyboard-circle",
            name: '分析对话',
            isNew: true
        },
    ];

    return (
        <>
            <PeiLianHeader/>
            {/*<VoiceLayout />*/}

            <div className='head'>
                <button className='history-toggle-btn' onClick={() => setShowHistory(!showHistory)}>
                    <i className="fas fa-history"></i>
                </button>
                <div className='head-set' onClick={handleOpen}></div>
                <div className='head-title'>{robotName}</div>
                <div className='head-clear' onClick={() => navigate('/peilian/robots')}>
                    <img src={'/peilian/back.svg'} alt="返回" />
                </div>
            </div>
            <div className='robot-container'>
                {showRadar && chartData && <RadarChart data={chartData} onClose={() => setShowRadar(false)} />}
                {showHistory && (
                    <div className='robot-history'>
                        <button className='new-session-btn' onClick={() => setSessionOpen(true)}>新建会话</button>
                        <h3>会话历史</h3>
                        <ul className='history'>
                            {history.map((session) => (
                                <li key={session.id} className={selectedSessionId === session.id ? 'selected-session' : ''}>
                                    <Dropdown overlay={menu(session.id)} trigger={['contextMenu']}>
                                        {/*<p onClick={() => loadSession(session.id)}>会话 {session.Title}</p>*/}
                                        <div className="session-item" onClick={() => loadSession(session.id)}>
                                            <span className="session-dot">•</span>
                                            <p>会话 {session.Title}</p>
                                        </div>
                                    </Dropdown>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={showHistory ? 'chat' : 'chatfull'}>
                    <Chat
                        placeholder="有问题尽管问我~"
                        messages={messages}
                        renderMessageContent={renderMessageContent}
                        quickReplies={defaultQuickReplies}
                        onQuickReplyClick={handleQuickReplyClick}
                        onSend={handleSend}
                        onInputTypeChange={inputTypeChange}
                        recorder={{
                            canRecord: true,
                            onStart: startRecording,
                            onEnd: stopRecording,
                            onCancel: cancelRecording,
                        }}
                    />
                </div>
            </div>
            <Modal
                active={open}
                title="设置对话轮数"
                showClose={false}
                onClose={handleClose}
                actions={[
                    {
                        label: '确认',
                        color: 'primary',
                        onClick: handleConfirm,
                    },
                    {
                        label: '取消',
                        onClick: handleClose,
                    },
                ]}
            >
                <div>
                    <p><span className='requird-span'>*</span>对话轮数</p>
                    <Input value={batch} onChange={val => handleSetValue(val)} placeholder="请输入对话轮数（1-10）" />
                </div>
            </Modal>
            <AntdModal
                title="创建新会话"
                visible={sessionopen}
                onOk={() => {
                    newSession();
                    handleSessionClose();
                }}
                onCancel={handleSessionClose}
            >
                <div>
                    <p>会话标题</p>
                    <Input value={newSessionTitle} onChange={val => setNewSessionTitle(val)} placeholder="请输入会话标题" />
                </div>
            </AntdModal>
        </>
    );
};

export default RobotChat;