//
//
// import React, { useState, useEffect, useRef } from 'react';
// import { XfVoiceDictation } from '@muguilin/xf-voice-dictation';
// import { Mic } from 'lucide-react';
// import PeiLianHeader from "../../headers/PeiLianHeader";
// import './XunfeiTest.css';  // 引入CSS文件
// import beepSound from './beep.wav';
// import axios from "axios";
// import {toast} from "@chatui/core";  // 假设音频文件名为 beep.wav
//
// const VoiceChatStream = () => {
//     const [isRecording, setIsRecording] = useState(false);
//     const [userStatus, setUserStatus] = useState('Connected');
//     const [recognizedText, setRecognizedText] = useState('');
//     const [outputText, setOutputText] = useState('');
//
//     const [botStatus, setBotStatus] = useState('Connected');
//     const [volume, setVolume] = useState(0);
//     const inputRef = useRef(null);
//     const outputRef = useRef(null);
//     const xfVoiceRef = useRef(null);
//     const audioContextRef = useRef(null);
//     const analyserRef = useRef(null);
//     const dataArrayRef = useRef(null);
//     const mediaStreamRef = useRef(null);
//     const audioRef = useRef(null);
//     let times = useRef(null);
//     // const baseUrlApi = "http://localhost:8080";
//     const baseUrlApi = process.env.REACT_APP_API_URL;
//
//
//     useEffect(() => {
//         audioRef.current = new Audio(beepSound);
//
//         // 实例化迅飞语音听写（流式版）WebAPI
//         xfVoiceRef.current = new XfVoiceDictation({
//             APPID: '5ec244d5',
//             APISecret: '37912e3e3f205e2a6201ec290452470a',
//             APIKey: '78b6c006f1f3df5e24d315e3dff09212',
//
//             onWillStatusChange: function (oldStatus, newStatus) {
//                 console.log('识别状态：', oldStatus, newStatus);
//             },
//
//             onTextChange: function (text) {
//                 console.log('识别内容：', text);
//                 setRecognizedText(prevText => prevText + text);
//                 // if (inputRef.current) {
//                 //     inputRef.current.value = text;
//                 // }
//
//                 if (text) {
//                     clearTimeout(times.current);
//                     times.current = setTimeout(() => {
//                         xfVoiceRef.current.stop();
//                         setUserStatus('Connected');
//                         setIsRecording(false);
//                     }, 3000);
//                 }
//             },
//
//             onError: function (error) {
//                 console.log('错误信息：', error);
//             },
//         });
//
//         return () => {
//             clearTimeout(times.current);
//             if (audioContextRef.current) {
//                 audioContextRef.current.close();
//             }
//         };
//     }, []);
//
//     useEffect(() => {
//         if (isRecording) {
//             startAnalyser();
//         } else {
//             stopAnalyser();
//         }
//     }, [isRecording]);
//
//     useEffect(() => {
//         audioRef.current.onended = () => {
//             setBotStatus('Connected');
//         };
//     }, []);
//
//     async function query(val){
//         console.log("提问",val)
//
//         axios.post(`${baseUrlApi}/agent/query`, {
//             robot_id:"1",
//             query: val,
//             session_id: "591fbe09-1dc8-49f0-a671-61d27021ac43",
//         })
//             .then((res) => {
//                 const answer = res.data.msg.data.answer;
//                 setOutputText(answer)
//
//                 return answer
//             })
//             .catch((err) => {
//                 console.log(err);
//                 toast.fail("出错啦！请稍后再试");
//                 return ""
//             });
//
//     }
//
//     const startAnalyser = async () => {
//         if (!audioContextRef.current) {
//             audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//         }
//         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//         mediaStreamRef.current = stream;
//         const source = audioContextRef.current.createMediaStreamSource(stream);
//
//         analyserRef.current = audioContextRef.current.createAnalyser();
//         analyserRef.current.fftSize = 256;
//         const bufferLength = analyserRef.current.frequencyBinCount;
//         dataArrayRef.current = new Uint8Array(bufferLength);
//
//         source.connect(analyserRef.current);
//
//         const getVolume = () => {
//             analyserRef.current.getByteFrequencyData(dataArrayRef.current);
//             let values = 0;
//             let average;
//
//             const length = dataArrayRef.current.length;
//             for (let i = 0; i < length; i++) {
//                 values += dataArrayRef.current[i];
//             }
//
//             average = values / length;
//             setVolume(average);
//
//             if (isRecording) {
//                 requestAnimationFrame(getVolume);
//             }
//         };
//
//         getVolume();
//     };
//
//     const stopAnalyser = () => {
//         if (mediaStreamRef.current) {
//             mediaStreamRef.current.getTracks().forEach(track => track.stop());
//         }
//         if (audioContextRef.current) {
//             audioContextRef.current.close();
//             audioContextRef.current = null;
//         }
//         setVolume(0);
//     };
//
//     const startRecording = () => {
//         setRecognizedText("");
//         setOutputText("");
//         xfVoiceRef.current.start();
//         setIsRecording(true);
//         setUserStatus('Speaking');
//     };
//
//     const stopRecording = async () => {
//         xfVoiceRef.current.stop();
//         setIsRecording(false);
//         setUserStatus('Connected');
//         setVolume(0);
//         if (recognizedText!==""){
//             const response = await query(recognizedText);
//             if (response !== "") {
//                 setBotStatus('Speaking');
//                 const utterance = new SpeechSynthesisUtterance(response);
//                 speechSynthesis.speak(utterance);
//                 setBotStatus('Connected');
//             }
//         }
//
//
//         // audioRef.current.play();
//     };
//
//     return (
//         <div className="min-h-screen flex flex-col">
//             <PeiLianHeader />
//             <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
//                 <div className="w-96 bg-white rounded-lg shadow-lg overflow-hidden">
//                     <div className="p-4 bg-gray-200 text-center text-sm font-semibold">
//                         {userStatus}
//                     </div>
//                     <div className="p-8 flex justify-center">
//                         <div className={`w-64 h-64 rounded-full bg-gray-300 flex items-center justify-center ${botStatus === 'Speaking' ? 'bot-speaking' : ''}`}>
//                             <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center">
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                                 <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="px-4 py-2 flex justify-between text-sm">
//                         <div>
//                             <div className="text-gray-500">USER STATUS</div>
//                             <div className={userStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
//                                 {userStatus}
//                             </div>
//                         </div>
//                         <div className="text-center">
//                             <div className="text-gray-500">LATENCY</div>
//                             <div>300<span className="text-gray-500 text-xs">ms</span></div>
//                             <div className="text-gray-500 text-xs">avg 300 ms</div>
//                         </div>
//                         <div className="text-right">
//                             <div className="text-gray-500">BOT STATUS</div>
//                             <div className={botStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
//                                 {botStatus}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="mt-8 flex items-center">
//                     <button
//                         className={`w-16 h-16 rounded-full flex items-center justify-center ${
//                             isRecording ? 'bg-red-500' : 'bg-blue-500'
//                         } text-white shadow-lg transition-colors duration-300`}
//                         onMouseDown={startRecording}
//                         onMouseUp={stopRecording}
//                         onTouchStart={startRecording}
//                         onTouchEnd={stopRecording}
//                     >
//                         <Mic size={32} />
//                     </button>
//                 </div>
//                 <div className="flex ml-4 space-x-1 items-end">
//                     {Array.from({ length: Math.floor(volume / 10) }).map((_, index) => (
//                         <div key={index} className="h-10 w-1 mt-8 bg-green-500" style={{ height: `${10 + index * 5}px` }}></div>
//                     ))}
//                 </div>
//                 <div className="mt-8 w-96">
//                     <input
//                         ref={inputRef}
//                         type="text"
//                         className="cool-input"
//                         value={recognizedText}
//                         readOnly
//                     />
//                 </div>
//
//                 <div className="mt-8 w-96">
//                     <p>回答</p>
//                     <input
//                         ref={outputRef}
//                         type="text"
//                         className="cool-input"
//                         value={outputText}
//                         readOnly
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default VoiceChatStream;
//
//
//
import React, { useState, useEffect, useRef } from 'react';
import { XfVoiceDictation } from '@muguilin/xf-voice-dictation';
import { Mic } from 'lucide-react';
import PeiLianHeader from "../../headers/PeiLianHeader";
import './XunfeiTest.css';
import axios from "axios";
import { toast } from "@chatui/core";

const VoiceChatStream = () => {
    const [isListening, setIsListening] = useState(false);
    const [userStatus, setUserStatus] = useState('Connected');
    // const [recognizedText, setRecognizedText] = useState('');
    const [inputText, setInputText] = useState('');

    const [outputText, setOutputText] = useState('');
    const [botStatus, setBotStatus] = useState('Connected');
    const [volume, setVolume] = useState(0);
    let recognizedText = ""
    const xfVoiceRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const silenceTimerRef = useRef(null);
    const isSpeakingRef = useRef(false);
    const lastRecognitionRef = useRef('');

    const baseUrlApi = process.env.REACT_APP_API_URL;

    useEffect(() => {
        initializeVoiceRecognition();

        return () => {
            stopListening();
        };
    }, []);

    useEffect(() => {
        if (isListening) {
            startAnalyser();
        } else {
            stopAnalyser();
        }
    }, [isListening]);

    const initializeVoiceRecognition = () => {
        xfVoiceRef.current = new XfVoiceDictation({
            APPID: '5ec244d5',
            APISecret: '37912e3e3f205e2a6201ec290452470a',
            APIKey: '78b6c006f1f3df5e24d315e3dff09212',
            onWillStatusChange: (oldStatus, newStatus) => {
                console.log('Recognition status:', oldStatus, newStatus);
                // if (newStatus === 'end') {
                //     // 当识别结束时，重新开始新的识别
                //     setTimeout(() => {
                //         if (isListening) {
                //             xfVoiceRef.current.stop();
                //         }
                //     }, 100);
                // }
            },
            onTextChange: (text) => {
                console.log('Recognized content:', text);
                // setRecognizedText(prevText => prevText + text);
                if (text!==""){
                setInputText(text)
                recognizedText = text;
                isSpeakingRef.current = true;
                resetSilenceTimer();
                }
            },
            onError: (error) => {
                console.log('Error:', error);
                toast.fail("Recognition error. Please try again.");
            },
        });
    };

    const startListening = () => {
        xfVoiceRef.current.start();
        setIsListening(true);
        setUserStatus('Listening');
        // setRecognizedText('');
        recognizedText = "";
        setInputText("");
        setOutputText("");
    };

    const stopListening = () => {
        xfVoiceRef.current.stop();
        setIsListening(false);
        setUserStatus('Connected');
        setVolume(0);
        clearTimeout(silenceTimerRef.current);
    };

    const resetSilenceTimer = () => {
        clearTimeout(silenceTimerRef.current);
        silenceTimerRef.current = setTimeout(() => {
            if (isSpeakingRef.current) {
                isSpeakingRef.current = false;
                handleSilence();
            }
        }, 1000); // 1 second of silence
    };

    const handleSilence = async () => {
        console.log("handleSilence", recognizedText);
        if (recognizedText !== lastRecognitionRef.current && recognizedText.trim() !== '') {
            lastRecognitionRef.current = recognizedText;
            const response = await query(recognizedText);
            xfVoiceRef.current.stop();

            xfVoiceRef.current.start();
            if (response !== "") {
                setBotStatus('Speaking');
                speakResponse(response);
            }
            // setRecognizedText('');
            recognizedText = "";
        }
    };

    const speakResponse = (text) => {
        setBotStatus('Speaking');
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.onend = () => {
            setBotStatus('Connected');
        };
        speechSynthesis.cancel(); // Cancel any ongoing speech
        speechSynthesis.speak(utterance);
    };

    const startAnalyser = async () => {
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaStreamRef.current = stream;
        const source = audioContextRef.current.createMediaStreamSource(stream);

        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        source.connect(analyserRef.current);

        const getVolume = () => {
            analyserRef.current.getByteFrequencyData(dataArrayRef.current);
            let values = 0;
            const length = dataArrayRef.current.length;
            for (let i = 0; i < length; i++) {
                values += dataArrayRef.current[i];
            }
            const average = values / length;
            setVolume(average);

            if (isListening) {
                requestAnimationFrame(getVolume);
            }
        };

        getVolume();
    };

    const stopAnalyser = () => {
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        setVolume(0);
    };

    async function query(val) {
        console.log("Question:", val);
        try {
            const response = await axios.post(`${baseUrlApi}/agent/query`, {
                robot_id: "1",
                query: val,
                session_id: "591fbe09-1dc8-49f0-a671-61d27021ac43",
            });
            const answer = response.data.msg.data.answer;
            setOutputText(answer);
            return answer;
        } catch (err) {
            console.log(err);
            toast.fail("Error occurred. Please try again later.");
            return "";
        }
    }

    return (
        <div className="min-h-screen flex flex-col">
            <PeiLianHeader />
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                <div className="w-96 bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-4 bg-gray-200 text-center text-sm font-semibold">
                        {userStatus}
                    </div>
                    <div className="p-8 flex justify-center">
                        <div className={`w-64 h-64 rounded-full bg-gray-300 flex items-center justify-center ${botStatus === 'Speaking' ? 'bot-speaking' : ''}`}>
                            <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center">
                                <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
                                <div className="w-2 h-2 rounded-full bg-black mx-1"></div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-2 flex justify-between text-sm">
                        <div>
                            <div className="text-gray-500">USER STATUS</div>
                            <div className={userStatus === 'Listening' ? 'text-green-500' : 'text-blue-500'}>
                                {userStatus}
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="text-gray-500">LATENCY</div>
                            <div>300<span className="text-gray-500 text-xs">ms</span></div>
                            <div className="text-gray-500 text-xs">avg 300 ms</div>
                        </div>
                        <div className="text-right">
                            <div className="text-gray-500">BOT STATUS</div>
                            <div className={botStatus === 'Speaking' ? 'text-green-500' : 'text-blue-500'}>
                                {botStatus}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 flex items-center">
                    <button
                        className={`w-16 h-16 rounded-full flex items-center justify-center ${
                            isListening ? 'bg-red-500' : 'bg-blue-500'
                        } text-white shadow-lg transition-colors duration-300`}
                        onClick={() => isListening ? stopListening() : startListening()}
                    >
                        <Mic size={32} />
                    </button>
                </div>
                <div className="flex ml-4 space-x-1 items-end">
                    {Array.from({ length: Math.floor(volume / 10) }).map((_, index) => (
                        <div key={index} className="h-10 w-1 mt-8 bg-green-500" style={{ height: `${10 + index * 5}px` }}></div>
                    ))}
                </div>
                <div className="mt-8 w-96">
                    <input
                        type="text"
                        className="cool-input"
                        value={inputText}
                        readOnly
                    />
                </div>
                <div className="mt-8 w-96">
                    <p>回答</p>
                    <input
                        type="text"
                        className="cool-input"
                        value={outputText}
                        readOnly
                    />
                </div>
            </div>
        </div>
    );
};

export default VoiceChatStream;