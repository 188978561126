import React, { useState } from 'react';
import './TTS.css';

const TTS = () => {
    const [text, setText] = useState('');

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleSpeak = () => {
        if (text.trim() === '') {
            return;
        }

        const utterance = new SpeechSynthesisUtterance(text);
        speechSynthesis.speak(utterance);
    };

    return (
        <div className="tts-container">
            <h1>Text to Speech</h1>
            <textarea
                className="tts-textarea"
                value={text}
                onChange={handleTextChange}
                placeholder="Enter text here..."
            ></textarea>
            <button className="tts-button" onClick={handleSpeak}>Speak</button>
        </div>
    );
};

export default TTS;
