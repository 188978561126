import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './App.css';
import DefaultPage from "./components/peilian/DefaultPage";
import LoginPage from "./components/login/LoginPage";
import HomePage from "./components/home/HomePage";
import PeiLianHome from "./pages/Home";
import AboutUs from "./components/home/AboutUs";
import TeamPage from "./components/home/TeamPage";
import RobotList from "./components/peilian/RobotList";
import RobotChat from "./components/peilian/RobotChat";
import CreateRobot from "./components/peilian/CreateRobot";
import RobotConfiguration from "./components/peilian/RobotConfiguration";
import ChatStream from "./components/peilian/ChatStreamMod/ChatStream";
import VoiceChatStream from "./components/peilian/ChatStreamMod/ChatStream";
import ChatStreamHome from "./components/peilian/ChatStreamMod/ChatStreamHome";
import ChatStreamConfiguration from "./components/peilian/ChatStreamMod/Configuration";
import ProtectedRoute from './components/login/ProtectedRoute';
import { UserProvider } from './components/contexts/UserContext';
import XunfeiTest from "./components/peilian/ChatStreamMod/XunfeiTest";
import QuestionList from "./components/peixun/QuestionList";
import CreateQuestion from "./components/peixun/CreateQuestion";
import PeiXunHome from "./components/peixun/Home";
import TTS from "./components/peilian/ChatStreamMod/TTS";
import JobsPage from "./components/home/jobs";
import ContactUs from "./components/home/Contact";
import Works from "./components/home/works/Works";
import XiaogeAssistantPage from "./components/home/works/xiaoge";
import RealTimeVoicePage from "./components/home/works/Voice";

const { Header, Content } = Layout;

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick = (e) => {
        console.log('Navigating to', e.key);
        navigate(e.key);
    };

    // 检查当前路径是否是 /home
    const showNavbar = location.pathname !== '/home' && location.pathname !=='/' && location.pathname!=='/login'&& location.pathname!=='/peilian'&& location.pathname!=='/peilian/home';

    return (
        <UserProvider>
            <Layout style={{ minHeight: '100vh' }}>
                <Content style={{ padding: '0 0px' }}>
                    <Routes>
                        <Route path="/peilian/login" element={<LoginPage />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/team" element={<TeamPage />} />
                        <Route path="/jobs" element={<JobsPage />} />
                        <Route path="/contact" element={<ContactUs />} />
                        <Route path="/works" element={<Works />} />
                        <Route path="/xiaoge" element={<XiaogeAssistantPage />} />
                        <Route path="/voice" element={<RealTimeVoicePage />} />

                        <Route path="/peixun/question"  element={<QuestionList/>} />
                        <Route path="/peixun/create" element={<CreateQuestion/>} />
                        <Route path="/peixun/home" element={<PeiXunHome/>} />
                        {/* Protected Routes */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/peilian" element={<DefaultPage />} />
                            <Route path="/peilian/home" element={<PeiLianHome />} />
                            <Route path="/peilian/robots" element={<RobotList />} />
                            <Route path="/peilian/robots/:id" element={<RobotChat />} />
                            <Route path="/peilian/robots/create" element={<CreateRobot />} />
                            <Route path="/peilian/robots/:id/configuration" element={<RobotConfiguration />} />
                            <Route path="/peilian/chatStream" element={<VoiceChatStream />} />
                            <Route path="/peilian/chatStream/home" element={<ChatStreamHome />} />
                            <Route path="/peilian/chatStream/configuration" element={<ChatStreamConfiguration />} />
                            <Route path="/peilian/xunfei/test" element={<XunfeiTest />} />
                            <Route path="/peilian/tts" element={<TTS />} />

                        </Route>
                    </Routes>
                </Content>
            </Layout>
         </UserProvider>
    );
};

export default App;
