import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const PeiLianHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, clearUser } = useContext(UserContext);

    const handleLogout = () => {
        clearUser(); // Clear user state
        navigate('/peilian/login'); // Navigate to login page
    };

    const menuItems = [


        { name: '问题列表', path: '/peixun/question' },
        { name: '创建问题', path: '/peixun/create' },
        { name: '生成考题', path: '/peixun/random' },
    ];

    return (
        <header className="bg-white shadow-md">
            <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                <div className="flex items-center">
                    <img src="/peilian/logo.png" alt="Logo" className="h-8 w-auto mr-3" onClick={()=>{navigate('/')}}/>
                    <div
                        className="text-xl font-bold text-gray-800 cursor-pointer"
                        onClick={() => navigate('/peilian/robots')}
                    >
                        格莱美智能陪练
                    </div>
                </div>
                <nav>
                    <ul className="flex space-x-6">
                        {user && menuItems.map((item) => (
                            <li key={item.name}>
                                <a
                                    href={item.path}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(item.path);
                                    }}
                                    className={`text-sm font-medium hover:text-blue-600 transition-colors ${
                                        location.pathname === item.path ? 'text-blue-600' : 'text-gray-600'
                                    }`}
                                >
                                    {item.name}
                                </a>
                            </li>
                        ))}
                        {/*{user ? (*/}
                        {/*    <li>*/}
                        {/*        <button*/}
                        {/*            onClick={handleLogout}*/}
                        {/*            className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors"*/}
                        {/*        >*/}
                        {/*            退出登录*/}
                        {/*        </button>*/}
                        {/*    </li>*/}
                        {/*) : (*/}
                        {/*    <li>*/}
                        {/*        <a*/}
                        {/*            href="/peilian/login"*/}
                        {/*            onClick={(e) => {*/}
                        {/*                e.preventDefault();*/}
                        {/*                navigate('/peilian/login');*/}
                        {/*            }}*/}
                        {/*            className={`text-sm font-medium hover:text-blue-600 transition-colors ${*/}
                        {/*                location.pathname === '/peilian/login' ? 'text-blue-600' : 'text-gray-600'*/}
                        {/*            }`}*/}
                        {/*        >*/}
                        {/*            登录*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*)}*/}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default PeiLianHeader;
