import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ file }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoading(false);
    };

    const onDocumentLoadError = (error) => {
        console.error(error);
        setError('无法加载PDF文件');
        setLoading(false);
    };

    return (
        <div className="pdf-viewer">
            {loading && <p className="text-center text-gray-500">加载中...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}
            {!loading && !error && (
                <>
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        className="border border-gray-300 rounded-md"
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                className="mb-4"
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>
                    <p className="mt-4 text-center">
                        第 {pageNumber} 页，共 {numPages} 页
                    </p>
                    <div className="flex justify-center mt-4 space-x-4">
                        <button
                            onClick={() => setPageNumber((page) => Math.max(page - 1, 1))}
                            disabled={pageNumber <= 1}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                        >
                            上一页
                        </button>
                        <button
                            onClick={() => setPageNumber((page) => Math.min(page + 1, numPages))}
                            disabled={pageNumber >= numPages}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                        >
                            下一页
                        </button>
                    </div>
                    <div className="mt-4 flex justify-center">
                        <input
                            type="number"
                            value={pageNumber}
                            onChange={(e) => setPageNumber(Number(e.target.value))}
                            min="1"
                            max={numPages}
                            className="w-16 text-center border border-gray-300 rounded-md"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default PdfViewer;
